import React from "react";
import { uniqid } from "../../helpers/helpers";
import { scrollToCategory } from "../../helpers/scroll";

const SideElement = (props) => {
    const {
        id,
        name,
        tabActive,
        categories,
        categoryActive,
        setMenuActive,
        setCurrentCategoryActive,
    } = props;

    return (
        <li
            data-v-816580ca
            data-side-menu-id={id}
            className="side-menu__category"
            data-testid={`menuSection-${id}`}
        >
            <a
                data-v-816580ca
                onClick={() => setMenuActive(id)}
                className={`router-link-active router-link-exact-active side-menu__category--link ${
                    tabActive == id ? "active-category" : ""
                }`}
                style={
                    tabActive == id
                        ? { color: "#fafafa", backgroundColor: "#9B643C" }
                        : { color: "#fafafa" }
                }
                aria-current="page"
            >
                <div data-v-816580ca>{name}</div>
            </a>
            {tabActive == id ? (
                <ul data-v-816580ca>
                    {categories.map((category) => (
                        <li
                            key={uniqid()}
                            data-v-816580ca
                            className={`${
                                categoryActive == category.id
                                    ? "active-item"
                                    : ""
                            }  side-menu__item`}
                            data-testid={`menuCategory-${category.id}`}
                            onClick={() => {
                                scrollToCategory(category.id);
                                setTimeout(
                                    () => setCurrentCategoryActive(category.id),
                                    1000
                                );
                            }}
                        >
                            <a
                                data-v-816580ca
                                className="side-menu__link"
                                style={
                                    categoryActive == category.id
                                        ? { color: "#fafafa" }
                                        : { color: "#ccc" }
                                }
                            >
                                {category.name}
                            </a>
                        </li>
                    ))}
                </ul>
            ) : null}
        </li>
    );
};

export default SideElement;
