import React, { useRef, useEffect } from "react";
import { getCategory, uniqid } from "../../helpers/helpers";
import mobxStore from "../../mobx/mobxStore";
import { observer } from "mobx-react-lite";

const DishList = observer((props) => {
    const {
        setCurrentCategoryActive,
        setModalFoodActive,
      
    } = mobxStore;
    const { categoryId, observerRefs, index } = props;
    let { dishes } = props;
    let { lastScrollY } = props;
    const observers = useRef([]);
    const category = getCategory(categoryId);
    const sections = useRef([]);
    const observerCallback = async (e, key) => {
        if (e.length && e[0].isIntersecting) {
            setCurrentCategoryActive(
                Number(e[0].target.attributes.getNamedItem("data-id").value)
            );
        }
    };

    useEffect(() => {
        if (observerRefs.current?.length && observers.current) {
            Array.from(Array(10).keys()).forEach((_u, key) => {
                observers.current[key] = new IntersectionObserver(
                    (e) => observerCallback(e, key),
                    {
                        root: null,
                        rootMargin: "0px",
                        threshold: 0.3,
                    }
                );
                if (observerRefs.current[key]) {
                    observers.current[key].observe(observerRefs.current[key]);
                }
            });
        }
        return () =>
            observers.current?.forEach((observer) =>
                observer?.current?.disconnect()
            );
    }, [observerRefs, observers]);


   

    return (
        <div
            data-v-6cdedb19
            data-v-310e2a5f
            className="dish-list"
            data-id={category.id}
            data-scroll
            ref={(el) => (observerRefs.current[index] = el)}
        >
            <h2
                data-v-6cdedb19
                className="dish-list--title"
                data-testid="categoryTitle"
                style={{ color: "#fafafa" }}
            >
                {category.name}
            </h2>
            {/**/}
            {dishes.map((dish, key) => (
                <div
                    key={uniqid()}
                    data-v-a352d425
                    data-v-d4718356
                    data-v-310e2a5f
                    className="menu-list-item"
                    data-item-id={dish.id}
                    data-testid={`dish-${dish.id}`}
                    onClick={() => setModalFoodActive(dish.id)}
                >
                    <div data-v-a352d425 className="menu-list-item-content">
                        <div
                            data-v-a352d425
                            className="menu-list-item-text-wrapper"
                        >
                            <h4
                                data-v-a352d425
                                className="item-title"
                                data-testid="dishTitle"
                                style={{ color: "#fafafa" }}
                            >
                                {dish.title}
                            </h4>
                            <div
                                data-v-a352d425
                                className="price"
                                data-testid="dishPrice"
                                style={{ color: "#fafafa" }}
                            >
                                <span data-v-a352d425>{dish.minPrice} €</span>
                            </div>
                            {/**/}
                            {dish.description ? (
                                <div
                                    data-v-a352d425
                                    className="item-description decorate-supported-text"
                                    data-testid="dishDescription"
                                    style={{ color: "#fafafa" }}
                                >
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: dish.description,
                                        }}
                                    ></p>
                                </div>
                            ) : null}
                           
                           {typeof dish.dishVariants!='undefined'? dish.dishVariants[0].amount ||
                            dish.dishVariants[0].unit ? (
                                <div
                                    data-v-a352d425
                                    className="item-amount"
                                    data-testid="dishAmount"
                                    style={{ color: "#fafafa" }}
                                >
                                    {`${dish.dishVariants[0].amount} ${dish.dishVariants[0].unit}`}
                                </div>
                            ) : null:null}
                          
                        </div>
                    </div>
                    {dish.imageUrl ? (
                        <div data-v-a352d425 className="image-wrapper">
                            <img
                                data-v-a352d425
                                src={dish.imageUrl}
                                alt="КРАБ ТУРТО"
                                data-testid="dishImage"
                            />
                        </div>
                    ) : null}
                </div>
            ))}
        </div>
    );
});

export default DishList;
