import React, { useState } from "react";
import { findDishes, getDishes } from "../../helpers/helpers";
const SeachElement = (props) => {
    const { setDishesList, menuId,setCurrentMenuId } = props;
    const [search, setSearch] = useState("");
    const [lastMenuId,setlastMenuId]=useState(menuId);
    const setSearchHandler = (value = "") => {
        if (value.length >= 2) {
            setDishesList(findDishes(value));
            setCurrentMenuId(-1);
            document.querySelector("#app").scroll(0,0);;
        } else {
            setSearch((prevState) => {
                if (prevState.length < 2) {
                    setDishesList(getDishes(menuId));
                    setCurrentMenuId(lastMenuId);

                }else{
                    setDishesList(getDishes(menuId));
                    setCurrentMenuId(lastMenuId)
                }
                return value;
            });
        }
        setSearch(value);
    };

    return (
        <label
            data-v-2c722b87
            data-v-310e2a5f
            className="search-element"
            htmlFor="search-element"
            style={{ marginBottom: "24px", flexShrink: 0 }}
        >
            <input
                data-v-2c722b87
                type="text"
                placeholder="Name of the dish"
                data-testid="searchInput"
                value={search}
                onChange={(e) => setSearchHandler(e.target.value)}
            />
            {/* <span data-v-2c722b87 className="border" /> */}
            <div data-v-2c722b87 className="search-icon-wrapper">
                <div
                    data-v-4d1d4390
                    data-v-2c722b87
                    className="base-icon search-icon small"
                >
                    <div data-v-4d1d4390 className="base-icon-content">
                        <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g opacity="0.8" id="icon-search">
                                <path
                                    d="M19.4697 20.5303C19.7626 20.8232 20.2374 20.8232 20.5303 20.5303C20.8232 20.2374 20.8232 19.7626 20.5303 19.4697L19.4697 20.5303ZM13.9312 14.9919L19.4697 20.5303L20.5303 19.4697L14.9919 13.9312L13.9312 14.9919Z"
                                    fill="#ffffff"
                                />
                                <circle
                                    cx="10.1538"
                                    cy="10.1538"
                                    r="6.15385"
                                    stroke="#ffffff"
                                    strokeWidth="1.5"
                                />
                            </g>
                        </svg>
                    </div>
                    {/**/}
                </div>
            </div>
            {search.length > 0 && search.length < 2 ? (
                <div data-v-2c722b87 className="search-warning">
                    Введіть мінімум 2 символи
                </div>
            ) : null}
            {search.length > 0 ? (
                <div
                    data-v-2c722b87
                    className="clear-search-icon-wrapper"
                    onClick={() => setSearchHandler("")}
                >
                    <div
                        data-v-4d1d4390
                        data-v-2c722b87
                        className="base-icon clear-search-icon"
                    >
                        <div data-v-4d1d4390 className="base-icon-content">
                            <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g id="icon-cross-filled">
                                    <path
                                        d="M9.1312 8L11.3944 5.7376C11.5445 5.58749 11.6288 5.38389 11.6288 5.1716C11.6288 4.95931 11.5445 4.75571 11.3944 4.6056C11.2443 4.45549 11.0407 4.37116 10.8284 4.37116C10.6161 4.37116 10.4125 4.45549 10.2624 4.6056L8 6.8688L5.7376 4.6056C5.58749 4.45549 5.38389 4.37116 5.1716 4.37116C4.95931 4.37116 4.75571 4.45549 4.6056 4.6056C4.45549 4.75571 4.37116 4.95931 4.37116 5.1716C4.37116 5.38389 4.45549 5.58749 4.6056 5.7376L6.8688 8L4.6056 10.2624C4.45549 10.4125 4.37116 10.6161 4.37116 10.8284C4.37116 11.0407 4.45549 11.2443 4.6056 11.3944C4.75571 11.5445 4.95931 11.6288 5.1716 11.6288C5.38389 11.6288 5.58749 11.5445 5.7376 11.3944L8 9.1312L10.2624 11.3944C10.4125 11.5445 10.6161 11.6288 10.8284 11.6288C11.0407 11.6288 11.2443 11.5445 11.3944 11.3944C11.5445 11.2443 11.6288 11.0407 11.6288 10.8284C11.6288 10.6161 11.5445 10.4125 11.3944 10.2624L9.1312 8ZM8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16Z"
                                        stroke="none"
                                        strokeWidth="none"
                                        fill="currentColor"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_5897_18073">
                                        <rect
                                            width={16}
                                            height={16}
                                            fill="white"
                                        />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        {/**/}
                    </div>
                </div>
            ) : null}

            <div data-v-2c722b87 className="search-label" />
        </label>
    );
};

export default SeachElement;
