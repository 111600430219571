import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { getCategory, uniqid } from "../../helpers/helpers";
import mobxStore from "../../mobx/mobxStore";
import { toJS } from "mobx";

const DishListModal = (props) => {
    const { dishes ,categoryId,index} = props;
   console.log(dishes);
        const {setModalFoodActive}=mobxStore;
    const category = getCategory(categoryId);

    return (
        <div
            data-v-6cdedb19
            data-v-310e2a5f
            className="dish-list"
            data-id={category.id}
            data-scroll
          
        >
            <h2
                data-v-6cdedb19
                className="dish-list--title"
                data-testid="categoryTitle"
                style={{ color: "#fafafa" }}
            >
                {category.name}
            </h2>
            {/**/}
            {dishes.map((dish, key) => (
                <div
                    key={uniqid()}
                    data-v-a352d425
                    data-v-d4718356
                    data-v-310e2a5f
                    className="menu-list-item"
                    data-item-id={dish.id}
                    data-testid={`dish-${dish.id}`}
                    onClick={() => setModalFoodActive(dish.id)}
                >
                    <div data-v-a352d425 className="menu-list-item-content">
                        <div
                            data-v-a352d425
                            className="menu-list-item-text-wrapper"
                        >
                            <h4
                                data-v-a352d425
                                className="item-title"
                                data-testid="dishTitle"
                                style={{ color: "#fafafa" }}
                            >
                                {dish.title}
                            </h4>
                            <div
                                data-v-a352d425
                                className="price"
                                data-testid="dishPrice"
                                style={{ color: "#fafafa" }}
                            >
                                <span data-v-a352d425>{dish.minPrice} €</span>
                            </div>
                            {/**/}
                            {dish.description ? (
                                <div
                                    data-v-a352d425
                                    className="item-description decorate-supported-text"
                                    data-testid="dishDescription"
                                    style={{ color: "#fafafa" }}
                                >
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: dish.description,
                                        }}
                                    ></p>
                                </div>
                            ) : null}
                            {typeof dish.dishVariants!='undefined'? dish.dishVariants[0].amount ||
                            dish.dishVariants[0].unit ? (
                                <div
                                    data-v-a352d425
                                    className="item-amount"
                                    data-testid="dishAmount"
                                    style={{ color: "#fafafa" }}
                                >
                                    {`${dish.dishVariants[0].amount} ${dish.dishVariants[0].unit}`}
                                </div>
                            ) : null:null}
                         
                        </div>
                    </div>
                    {dish.imageUrl ? (
                        <div data-v-a352d425 className="image-wrapper">
                            <img
                                data-v-a352d425
                                src={dish.imageUrl}
                                alt="КРАБ ТУРТО"
                                data-testid="dishImage"
                            />
                        </div>
                    ) : null}
                </div>
            ))}
        </div>
    );
};

export default DishListModal;
