import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import mobxStore from "../../mobx/mobxStore";
import { findDishes } from "../../helpers/helpers";

const ModalSearch = observer(() => {
    const { searchModalActive,setDishesModal,setSearchModalActive } = mobxStore;
    const [searchTimeout, setSearchTimeout] = useState(null);

  
    const [search, setSearch] = useState("");

    const setSearchHandler = (value = "") => {
        setSearch(value);
    
        // Отменяем предыдущий таймер
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }
    
        // Устанавливаем новый таймер
        const timeoutId = setTimeout(() => {
            if (value.length >= 2) {
                const dishes = findDishes(value);
                console.log(dishes);
                setDishesModal(JSON.stringify(dishes));
                document.querySelector("#app").scrollTo(0, 0);
            } else {
                // Очистите результаты поиска, если нужно
                setDishesModal(null);
            }
        }, 500); // Задержка в 500 мс (можете изменить по необходимости)
    
        // Сохраняем идентификатор таймера
        setSearchTimeout(timeoutId);
    };
    useEffect(() => {
        return () => {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }
        };
    }, [searchTimeout]);
    

    if (!searchModalActive) {
        return <></>;
    }
    return (
        <div
            data-v-f04d4254
            className="head-menu absolute search"
            style={{ backgroundColor: "#634135" }}
        >
            <div data-v-f04d4254 className="head-menu-name content">
              
                <div data-v-f04d4254 className="search-wrapper">
                    <button
                        data-v-f04d4254
                        className="search-button-back"
                        data-testid="searchButton"
                        onClick={()=>setSearchModalActive(false)}
                    >
                        <div
                            data-v-4d1d4390
                            data-v-f04d4254
                            className="base-icon search-button-back-icon"
                        >
                            <div data-v-4d1d4390 className="base-icon-content">
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3 12H21M10 19L3 12L10 19ZM3 12L10 5L3 12Z"
                                        stroke="black"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        id="icon-arrow-back"
                                    />
                                </svg>
                            </div>
                            {/**/}
                        </div>
                    </button>
                    <label
                        data-v-2c722b87
                        data-v-f04d4254
                        className="search-element "
                        htmlFor="search-element"
                    >
                        <input
                            data-v-2c722b87
                            id="search-element"
                            className="search-modal"
                            type="text"
                            placeholder="Name of the dish"
                            data-testid="searchInput"
                            style={{ backgroundColor: "unset" }}
                            value={search}
                            onChange={(e)=>setSearchHandler(e.target.value)}
                        />
                     
                        <div data-v-2c722b87 className="search-icon-wrapper">
                            <div
                                data-v-4d1d4390
                                data-v-2c722b87
                                className="base-icon search-icon small"
                            >
                                <div
                                    data-v-4d1d4390
                                    className="base-icon-content"
                                >
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g opacity="0.8" id="icon-search">
                                            <path
                                                d="M19.4697 20.5303C19.7626 20.8232 20.2374 20.8232 20.5303 20.5303C20.8232 20.2374 20.8232 19.7626 20.5303 19.4697L19.4697 20.5303ZM13.9312 14.9919L19.4697 20.5303L20.5303 19.4697L14.9919 13.9312L13.9312 14.9919Z"
                                                fill="#fafafa"
                                            />
                                            <circle
                                                cx="10.1538"
                                                cy="10.1538"
                                                r="6.15385"
                                                stroke="#fafafa"
                                                strokeWidth="1.5"
                                            />
                                        </g>
                                    </svg>
                                </div>
                                {/**/}
                            </div>
                        </div>
                        {/**/}
                        {/**/}
                        <div data-v-2c722b87 className="search-label" />
                    </label>
                </div>
                {/**/}
            </div>
        </div>
    );
});

export default ModalSearch;
