import React from "react";
import mobxStore from "../../mobx/mobxStore";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router";

const HeaderMobile = observer(() => {
    const { searchModalActive, setSearchModalActive } = mobxStore;
    const navigate=useNavigate();
    if (searchModalActive) {
        return <></>;
    }
    return (
        <div
            data-v-f04d4254
            className="head-menu"
            style={{ backgroundColor: "#634135" }}
        >
            <div data-v-f04d4254 className="head-menu-name content">
                <div data-v-f04d4254 className="header-restaurant-logo">
                    <img
                        data-v-f04d4254
                        src="/logo.png"
                        loading="lazy"
                        alt="restaurant logo"
                        data-testid="restaurantIcon"
                        onClick={()=>navigate("/")}
                    />
                </div>
                <div data-v-f04d4254 className="search-wrapper">
                    {/**/}
                    <label
                        data-v-2c722b87
                        data-v-f04d4254
                        className="search-element collapsed"
                        htmlFor="search-element"
                        onClick={() => {
                            setSearchModalActive(true);
                        }}
                    >
                        <input
                            data-v-2c722b87
                            id="search-element"
                            type="text"
                            placeholder
                            data-testid="searchInput"
                        />
                        <span data-v-2c722b87 className="border" />
                        <div data-v-2c722b87 className="search-icon-wrapper">
                            <div
                                data-v-4d1d4390
                                data-v-2c722b87
                                className="base-icon search-icon"
                            >
                                <div
                                    data-v-4d1d4390
                                    className="base-icon-content"
                                >
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g opacity="0.8" id="icon-search">
                                            <path
                                                d="M19.4697 20.5303C19.7626 20.8232 20.2374 20.8232 20.5303 20.5303C20.8232 20.2374 20.8232 19.7626 20.5303 19.4697L19.4697 20.5303ZM13.9312 14.9919L19.4697 20.5303L20.5303 19.4697L14.9919 13.9312L13.9312 14.9919Z"
                                                fill="currentColor"
                                            />
                                            <circle
                                                cx="10.1538"
                                                cy="10.1538"
                                                r="6.15385"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                            />
                                        </g>
                                    </svg>
                                </div>
                                {/**/}
                            </div>
                        </div>
                        {/**/}
                        {/**/}
                        <div data-v-2c722b87 className="search-label" />
                    </label>
                </div>
            </div>
        </div>
    );
});

export default HeaderMobile;
