import React, { useEffect, useRef, useState } from "react";
import SideMenu from "./sideMenu";
import SeachElement from "./seachElement";
import Header from "./header";
import DishList from "./dishList";
import { observer } from "mobx-react-lite";
import { getDishes, getFirstCategory, uniqid } from "../../helpers/helpers";
import mobxStore from "../../mobx/mobxStore";
import HeaderMobile from "./headerMobile";
import HeadMobile from "./headMobile";
import DishListModal from "./dishListModal";
let lastScrollY;
let oldScrollY;
const Menu = observer(() => {
    const {
        menuId,
        setCurrentCategoryActive,
        setCurrentMenuId,
        searchModalActive,
        dishesMobx,
    } = mobxStore;

    console.log(menuId);
    const [dishesList, setDishesList] = useState(getDishes(menuId));

    const observerRefs = useRef([]);
    useEffect(() => {
        if (menuId > 0) {
            setDishesList(getDishes(menuId));
            setCurrentCategoryActive(getFirstCategory(menuId).id);
            document.querySelector("#app").scroll(0, 0);
        }
    }, [menuId]);
    // useEffect(() => {
    //     const elementIsVisibleInViewport = () => {
    //         console.log("detect scroll");
    //         const partiallyVisible=false;
    //         const elements = document.querySelectorAll("dish-list");

    //         // elements.forEach((el) => {
    //         //     const { top, left, bottom, right } = el.getBoundingClientRect();
    //         //     const { innerHeight, innerWidth } = window;
    //         //     return partiallyVisible
    //         //         ? ((top > 0 && top < innerHeight) ||
    //         //               (bottom > 0 && bottom < innerHeight)) &&
    //         //               ((left > 0 && left < innerWidth) ||
    //         //                   (right > 0 && right < innerWidth))
    //         //         : top >= 0 &&
    //         //               left >= 0 &&
    //         //               bottom <= innerHeight &&
    //         //               right <= innerWidth;
    //         // });
    //     };
    //     window.addEventListener("scroll", elementIsVisibleInViewport);
    //     elementIsVisibleInViewport();
    //     return()=>{   window.removeEventListener("scroll", elementIsVisibleInViewport)}
    // }, []);
    // useEffect(() => {
    //     const checkElementsVisibility = () => {
    //         console.log("detect scroll");

    //         const elements = document.querySelectorAll("dish-list");
    //         const { innerHeight, innerWidth } = window;

    //         elements.forEach((el) => {
    //             const { top, left, bottom, right } = el.getBoundingClientRect();

    //             const isVisible = top >= 0 &&
    //                               left >= 0 &&
    //                               bottom <= innerHeight &&
    //                               right <= innerWidth;

    //             if (isVisible) {
    //                 // Элемент полностью виден
    //                 // Здесь вы можете выполнить нужные действия, например, добавить класс
    //             } else {
    //                 // Элемент не виден
    //             }
    //         });
    //     };

    //     window.addEventListener("scroll", checkElementsVisibility);

    //     // Вызываем функцию сразу, чтобы проверить видимость при загрузке
    //     checkElementsVisibility();

    //     return () => {
    //         window.removeEventListener("scroll", checkElementsVisibility);
    //     };
    // }, []);
    return (
        <div data-v-310e2a5f className="layout-wrapper">
            <style>
                {`#app{
			height: 100%;
			width: 100%;
			overflow-y: auto;
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			-webkit-box-flex: 1;
			scrollbar-width: none;
			// background-color: rgba(255, 255, 255, 0.8);
		}
		#app::-webkit-scrollbar {
			display: none;
		}
		@keyframes dash {
			0% {
				stroke-dasharray: 1, 200;
				stroke-dashoffset: 0;
			}
			50% {
				stroke-dasharray: 89, 200;
				stroke-dashoffset: -35px;
			}
			100% {
				stroke-dasharray: 89, 200;
				stroke-dashoffset: -124px;
			}
		}`}
            </style>

            <div data-v-310e2a5f className="layout">
                <Header />
                <main data-v-310e2a5f className="main-content">
                    <HeaderMobile />
                    <HeadMobile menuId={menuId} />

                    <div data-v-310e2a5f className="side-wrapper">
                        <SeachElement
                            setDishesList={setDishesList}
                            menuId={menuId}
                            setCurrentMenuId={setCurrentMenuId}
                        />
                        <SideMenu />
                    </div>

                    <div data-v-310e2a5f className="layout-content">
                        {/**/}
                        <div data-v-310e2a5f style={{ display: "none" }}>
                            <div data-v-4e5ccac4 data-v-310e2a5f>
                                <div data-v-4e5ccac4>
                                    <div
                                        data-v-4e5ccac4
                                        className="skeleton-loader skeleton-loader--category-title"
                                    />
                                </div>
                                <div
                                    data-v-4e5ccac4
                                    className="trans-item even"
                                >
                                    <div
                                        className="skeleton-wrapper description-wrap"
                                        data-v-4e5ccac4
                                    >
                                        <div data-v-4e5ccac4>
                                            <div
                                                className="skeleton-loader skeleton-loader--title"
                                                data-v-4e5ccac4
                                            />
                                        </div>
                                        <div data-v-4e5ccac4>
                                            <div
                                                className="skeleton-loader skeleton-loader--price"
                                                data-v-4e5ccac4
                                            />
                                        </div>
                                        <div className="pale" data-v-4e5ccac4>
                                            <div
                                                className="skeleton-loader skeleton-loader--description"
                                                data-v-4e5ccac4
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="skeleton-wrapper image-wrap"
                                        data-v-4e5ccac4
                                    >
                                        <div
                                            className="skeleton-loader skeleton-loader--image"
                                            data-v-4e5ccac4
                                        />
                                    </div>
                                </div>
                                <div data-v-4e5ccac4 className="trans-item">
                                    <div
                                        className="skeleton-wrapper description-wrap"
                                        data-v-4e5ccac4
                                    >
                                        <div data-v-4e5ccac4>
                                            <div
                                                className="skeleton-loader skeleton-loader--title"
                                                data-v-4e5ccac4
                                            />
                                        </div>
                                        <div data-v-4e5ccac4>
                                            <div
                                                className="skeleton-loader skeleton-loader--price"
                                                data-v-4e5ccac4
                                            />
                                        </div>
                                        <div className="pale" data-v-4e5ccac4>
                                            <div
                                                className="skeleton-loader skeleton-loader--description"
                                                data-v-4e5ccac4
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="skeleton-wrapper image-wrap"
                                        data-v-4e5ccac4
                                    >
                                        <div
                                            className="skeleton-loader skeleton-loader--image"
                                            data-v-4e5ccac4
                                        />
                                    </div>
                                </div>
                                <div
                                    data-v-4e5ccac4
                                    className="trans-item even"
                                >
                                    <div
                                        className="skeleton-wrapper description-wrap"
                                        data-v-4e5ccac4
                                    >
                                        <div data-v-4e5ccac4>
                                            <div
                                                className="skeleton-loader skeleton-loader--title"
                                                data-v-4e5ccac4
                                            />
                                        </div>
                                        <div data-v-4e5ccac4>
                                            <div
                                                className="skeleton-loader skeleton-loader--price"
                                                data-v-4e5ccac4
                                            />
                                        </div>
                                        <div className="pale" data-v-4e5ccac4>
                                            <div
                                                className="skeleton-loader skeleton-loader--description"
                                                data-v-4e5ccac4
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="skeleton-wrapper image-wrap"
                                        data-v-4e5ccac4
                                    >
                                        <div
                                            className="skeleton-loader skeleton-loader--image"
                                            data-v-4e5ccac4
                                        />
                                    </div>
                                </div>
                                <div data-v-4e5ccac4 className="trans-item">
                                    <div
                                        className="skeleton-wrapper description-wrap"
                                        data-v-4e5ccac4
                                    >
                                        <div data-v-4e5ccac4>
                                            <div
                                                className="skeleton-loader skeleton-loader--title"
                                                data-v-4e5ccac4
                                            />
                                        </div>
                                        <div data-v-4e5ccac4>
                                            <div
                                                className="skeleton-loader skeleton-loader--price"
                                                data-v-4e5ccac4
                                            />
                                        </div>
                                        <div className="pale" data-v-4e5ccac4>
                                            <div
                                                className="skeleton-loader skeleton-loader--description"
                                                data-v-4e5ccac4
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="skeleton-wrapper image-wrap"
                                        data-v-4e5ccac4
                                    >
                                        <div
                                            className="skeleton-loader skeleton-loader--image"
                                            data-v-4e5ccac4
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            data-v-310e2a5f
                            className="full-width"
                            style={{ display: "none" }}
                        />
                        <div
                            data-v-74a785d5
                            data-v-310e2a5f
                            className="empty-search"
                            style={{ display: "none" }}
                        >
                            <div
                                data-v-74a785d5
                                className="empty-search-wrapper"
                            >
                                <div
                                    data-v-74a785d5
                                    className="empty-search-content"
                                >
                                    <img
                                        data-v-74a785d5
                                        src="/assets/empty-search-cat-FFwwpdL8.svg"
                                        alt="empty search"
                                        height={120}
                                        width={120}
                                    />
                                    <div
                                        data-v-74a785d5
                                        className="empty-search-title"
                                    >
                                        Шукали-шукали "" та не знайшли
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            data-v-310e2a5f
                            className="full-width"
                            style={searchModalActive ? { display: "none" } : {}}
                        >
                            {dishesList.map((dishes, index) => (
                                <DishList
                                    key={uniqid()}
                                    categoryId={dishes.categoryId}
                                    dishes={dishes.dishes}
                                    index={index}
                                    observerRefs={observerRefs}
                                    lastScrollY={lastScrollY}
                                />
                            ))}

                          
                        </div>
                        <div
                            data-v-310e2a5f
                            className="full-width"
                            style={searchModalActive ? {} : { display: "none" }}
                        >
                            {(() => {
                                try {
                                    return JSON.parse(dishesMobx).map(
                                        (dish, index) => (
                                            <DishListModal
                                                key={dish.id || index}
                                                categoryId={dish.categoryId}
                                                dishes={dish.dishes}
                                                index={index}
                                                
                                            />
                                        )
                                    );
                                } catch (error) {
                                    console.log(error);
                                    return null; // Возвращаем null, чтобы избежать ошибки
                                }
                            })()}
                          
                        </div>
                    </div>
                </main>
                {/**/}
                {/**/}
                {/**/}
                {/**/}
            </div>
            {/**/}
            {/**/}
        </div>
    );
});

export default Menu;
