import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import mobxStore from "../mobx/mobxStore";
import { searchDish } from "../helpers/helpers";
import OutsideAlerter from "../helpers/outsideAlerter";

const ModalFood = observer(() => {
    const { modalFoodActive, setModalFoodActive } = mobxStore;

    const dish = searchDish(modalFoodActive);

    if (modalFoodActive == -1 || !dish) {
        return <></>;
    }
    return (
        <div data-v-e6a69404 className="modal-mask">
            <div data-v-e6a69404 className="modal-wrap">
                <div
                    data-v-e6a69404
                    className="modal-container from-bottom-animate"
                >
                    <OutsideAlerter
                        onOutsideClick={() => setModalFoodActive(-1)}
                    >
                        <div data-v-e6a69404 className="modal-header">
                            <div
                                data-v-e6a69404
                                className="close-btn"
                                data-testid="modalCloseButton"
                                onClick={() => setModalFoodActive(-1)}
                            >
                                <div
                                    data-v-4d1d4390
                                    data-v-e6a69404
                                    className="base-icon close-btn--icon"
                                >
                                    <div
                                        data-v-4d1d4390
                                        className="base-icon-content"
                                    >
                                        <svg
                                            width={24}
                                            height={24}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M7 17L16.5 6.75"
                                                stroke="black"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                id="icon-close-modal"
                                            />
                                            <path
                                                d="M17.0007 17L6.75073 6.75"
                                                stroke="black"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                    </div>
                                    {/**/}
                                </div>
                            </div>
                        </div>
                        <div data-v-e6a69404 className="modal-body">
                            {dish.imageUrl ? (
                                <div data-v-e6a69404 className="image-wrapper">
                                    <img
                                        data-v-e6a69404
                                        className="dish-image"
                                        src={dish.imageUrl}
                                        alt={dish.title}
                                        data-testid="modalDishImage"
                                    />
                                </div>
                            ) : null}

                            <div data-v-e6a69404 className="dish-content">
                                <div
                                    data-v-e6a69404
                                    className="dish--title"
                                    data-testid="modalDishTitle"
                                >
                                    {dish.title}
                                </div>
                                <div
                                    data-v-e6a69404
                                    className="dish--price"
                                    data-testid="modalDishPrice"
                                >
                                    <p
                                        data-v-e6a69404
                                        className
                                        data-testid="modalDishStopListPrice"
                                    >
                                        {dish.minPrice} €
                                    </p>
                                    {/**/}
                                </div>
                                {dish.description ? (
                                    <div
                                        data-v-e6a69404
                                        className="dish--description decorate-supported-text"
                                        data-testid="modalDishDescription"
                                    >
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: dish.description,
                                            }}
                                        ></p>
                                    </div>
                                ) : null}
                                {dish.hasModifiers||typeof dish.dishVariants!='undefined' ? (
                                    <div
                                        data-v-e6a69404
                                        className="dish--amount"
                                        data-testid="modalDishAmount"
                                    >
                                        {dish?.dishVariants[0]?.description}{" "}
                                        {dish?.dishVariants[0]?.amount}{" "}
                                        {dish?.dishVariants[0]?.unit}
                                    </div>
                                ) : null}

                                {dish.hasModifiers ? (
                                    <div data-v-6f7e5dfd className="controls">
                                      
                                        {dish?.dishVariants[0]?.modifiers
                                            ?.length > 0 ? (
                                            <div
                                                data-v-6f7e5dfd
                                                className="controls--header"
                                            >
                                                <div data-v-6f7e5dfd>
                                                    <div
                                                        data-v-6f7e5dfd
                                                        className="subtitle"
                                                    >
                                                        Можна додати
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                        <div
                                            data-v-6f7e5dfd
                                            className="modifiers-list"
                                        >
                                            {dish?.dishVariants[0]?.modifiers?.map(
                                                (modifier) => (
                                                    <div
                                                        data-v-6f7e5dfd
                                                        data-testid="simpleModifier-633d338e-fcdb-49eb-9bd1-11b10928096b"
                                                    >
                                                        <div
                                                            data-v-6f7e5dfd
                                                            className="modifier-label"
                                                        >
                                                            <div
                                                                data-v-6f7e5dfd
                                                                className="modifier-signature"
                                                                data-testid="simpleModifierSignature-633d338e-fcdb-49eb-9bd1-11b10928096b"
                                                            >
                                                                {modifier.name}
                                                            </div>
                                                            {/**/}
                                                        </div>
                                                    </div>
                                                )
                                            )}

                                            {dish?.dishVariants[0]?.modifierGroups?.map(
                                                (group) => (
                                                    <Fragment>
                                                        <div
                                                            data-v-6f7e5dfd=""
                                                            class="controls--header"
                                                        >
                                                            <div data-v-6f7e5dfd="">
                                                                <div
                                                                    data-v-6f7e5dfd=""
                                                                    class="subtitle"
                                                                    data-testid="modifierGroupName"
                                                                >
                                                                    {group.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            data-v-6f7e5dfd
                                                            className="modifiers-list"
                                                        >
                                                            {group?.modifiers?.map(
                                                                (modifier) => (
                                                                    <div
                                                                        data-v-6f7e5dfd
                                                                        data-testid="groupModifier-a8541b90-3933-48fc-bfab-141c61e85ea8-26758ffe-9340-459f-a456-04664082d31e"
                                                                    >
                                                                        <div
                                                                            data-v-6f7e5dfd
                                                                            className="modifier-label"
                                                                        >
                                                                            <div
                                                                                data-v-6f7e5dfd
                                                                                className="modifier-signature"
                                                                                data-testid="groupModifierSignature-a8541b90-3933-48fc-bfab-141c61e85ea8"
                                                                            >
                                                                                {
                                                                                    modifier.name
                                                                                }
                                                                            </div>
                                                                            <div
                                                                                data-v-6f7e5dfd
                                                                                className="modifier-price"
                                                                                data-testid="groupModifierPrice-a8541b90-3933-48fc-bfab-141c61e85ea8"
                                                                            >
                                                                                {modifier?.price
                                                                                    ? `+ ${modifier?.price} €`
                                                                                    : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </Fragment>
                                                )
                                            )}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </OutsideAlerter>
                </div>
            </div>
        </div>
    );
});

export default ModalFood;
