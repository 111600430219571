import React from "react";
import {  useNavigate } from "react-router";

const Header=()=>{
    let navigate = useNavigate(); 
        return (
                <div data-v-acc523d2 data-v-310e2a5f className="header" style={{backgroundColor:"#634135"}}>
                <div data-v-acc523d2 className="header-wrapper">
                    <div
                        data-v-acc523d2
                        className="restaurant-item-container"
                    >
                        <div
                            data-v-b784aeb6
                            data-v-acc523d2
                            className="name-wrapper in-header"
                        >
                            <div
                                data-v-b784aeb6
                                className="icon-wrapper clickable-elem"
                                onClick={()=>navigate("/")}
                            >
                                <img
                                    data-v-b784aeb6
                                    src="/logo.png"
                                    loading="lazy"
                                    alt="restaurant logo"
                                    data-testid="restaurantIcon"
                                />
                            </div>
                            <div data-v-b784aeb6 className="info-wrapper">
                                <h2
                                    data-v-b784aeb6
                                    className="title"
                                    data-testid="restaurantTitle"
                                >
                                    SAVORI
                                </h2>
                                <div
                                    data-v-b784aeb6
                                    className="subtitle"
                                    data-testid="restaurantAddress"
                                >
                                    Vienna, Austria
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/**/}
            </div>
        )
};

export default Header;