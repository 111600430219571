import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import mobxStore from "../../mobx/mobxStore";
import { getCategoryList, getMenuItem, uniqid } from "../../helpers/helpers";
import { scrollToCategory } from "../../helpers/scroll";

const HeadMobile = observer((props) => {
    const { menuId } = props;
    const {
        searchModalActive,
        categoryActive,
        setCurrentCategoryActive,
        setModalMenuActive,
    } = mobxStore;

    const categories = getCategoryList(menuId);
    useEffect(() => {
        const element = document.querySelector("#head-menu-scroll-mobile");
        const elem = document.querySelector(
            `[data-testid="category-${categoryActive}"]`
        );

        if (element && elem) {
            const elemOffsetLeft = elem.offsetLeft;
            const elemOffsetWidth = elem.offsetWidth;
            const elementOffsetWidth = element.offsetWidth;

            console.log("elemOffsetLeft:", elemOffsetLeft);
            console.log("elemOffsetWidth:", elemOffsetWidth);
            console.log("elementOffsetWidth:", elementOffsetWidth);

            const left =
                elemOffsetLeft - elementOffsetWidth / 2 + elemOffsetWidth / 2;

            element.scrollTo({ left, behavior: "smooth" });
        }
    }, [categoryActive]);
    console.log(searchModalActive);
    
    if (searchModalActive) {
        return <></>;
    }
    return (
        <div
            data-v-bf8af33d
            data-v-310e2a5f
            className="head-menu-list-wrapper"
            style={{ backgroundColor: "#634135" }}
        >
            <div data-v-310e2a5f className="menu-switcher-wrapper">
                <button
                    data-v-2674a79e
                    data-v-310e2a5f
                    className="menu-switcher"
                    onClick={() => setModalMenuActive(true)}
                >
                    <p data-v-2674a79e className="menu-switcher-title">
                        {getMenuItem(menuId).name}
                    </p>
                    <div
                        data-v-4d1d4390
                        data-v-2674a79e
                        className="base-icon menu-switcher__icon"
                    >
                        <div data-v-4d1d4390 className="base-icon-content">
                            <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.00007 1C8.10332 0.999987 8.20547 1.0213 8.30011 1.06259C8.39475 1.10389 8.47985 1.16429 8.55006 1.24L11.8 4.73997C11.867 4.8122 11.9191 4.89691 11.9533 4.98927C11.9876 5.08163 12.0033 5.17982 11.9996 5.27825C11.9959 5.37668 11.9728 5.47342 11.9317 5.56293C11.8906 5.65245 11.8323 5.73299 11.76 5.79997C11.6878 5.86694 11.6031 5.91903 11.5107 5.95327C11.4184 5.9875 11.3202 6.00321 11.2218 5.9995C11.1233 5.99578 11.0266 5.97272 10.9371 5.93162C10.8476 5.89052 10.767 5.83219 10.7 5.75997L8.00007 2.85199L5.30009 5.75997C5.16483 5.90584 4.97716 5.992 4.77837 5.9995C4.57959 6.007 4.38596 5.93523 4.24009 5.79997C4.09422 5.66471 4.00806 5.47704 4.00056 5.27825C3.99306 5.07947 4.06483 4.88584 4.20009 4.73997L7.45007 1.24C7.52029 1.16429 7.60538 1.10389 7.70002 1.06259C7.79466 1.0213 7.89681 0.999987 8.00007 1ZM4.24009 10.1999C4.31231 10.133 4.39702 10.0809 4.48938 10.0466C4.58174 10.0124 4.67994 9.99666 4.77837 10.0004C4.87681 10.0041 4.97354 10.0272 5.06306 10.0683C5.15257 10.1094 5.23312 10.1677 5.30009 10.2399L8.00007 13.1479L10.7 10.2399C10.8353 10.0941 11.023 10.0079 11.2218 10.0004C11.4205 9.9929 11.6142 10.0647 11.76 10.1999C11.9059 10.3352 11.9921 10.5229 11.9996 10.7216C12.0071 10.9204 11.9353 11.1141 11.8 11.2599L8.55006 14.7599C8.47986 14.8356 8.39477 14.896 8.30013 14.9374C8.20549 14.9787 8.10333 15 8.00007 15C7.8968 15 7.79465 14.9787 7.70001 14.9374C7.60537 14.896 7.52028 14.8356 7.45007 14.7599L4.20009 11.2599C4.13311 11.1877 4.08101 11.103 4.04677 11.0106C4.01253 10.9183 3.99682 10.8201 4.00053 10.7216C4.00425 10.6232 4.02732 10.5265 4.06842 10.437C4.10952 10.3474 4.16786 10.2669 4.24009 10.1999Z"
                                    fill="currentColor"
                                    id="icon-arrow-top-down"
                                />
                            </svg>
                        </div>
                        {/**/}
                    </div>
                </button>
            </div>
            <div
                data-v-bf8af33d
                className="head-menu-list-content"
                data-testid="categoryList"
            >
                <div
                    data-v-bf8af33d
                    className="head-menu-list"
                    id="head-menu-scroll-mobile"
                >
                    {categories.map((category) => (
                        <a
                        key={uniqid()}
                            data-v-bf8af33d
                            className={`head-menu-item  ${
                                category.id == categoryActive
                                    ? "head-menu-item-active"
                                    : ""
                            }`}
                            data-testid={`category-${category.id}`}
                            onClick={() => {
                                scrollToCategory(category.id);
                                setTimeout(() => {
                                    setCurrentCategoryActive(category.id);
                                }, 500);
                            }}
                            style={category.id == categoryActive?{background:"#9B643C"}:{}}
                        >
                            {category.name}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
});

export default HeadMobile;
