export const scrollToCategory = (id, isMobile = false, isAlert = false, isSpecial = false, isPrev, isOneItem) => {
    const element = document.querySelector(`[data-id="${id}"]`);

    const top = element.offsetTop;
    const parentTop = element.parentElement.parentElement.offsetTop;
    const offset = isMobile ? 60 : 0;

    let alertHeight = 0;
    if (isAlert && isMobile) {
        const alert = document.querySelector(".alert-wrapper");
        const computedStyle = getComputedStyle(alert);
        alertHeight = Number(computedStyle.height.slice(0, -2));
    }

    // calculating height of block with special categories
    let specialHeight = 0;
    if (isSpecial) {
        const specialListElem = document.querySelector(
            isMobile ? "#marketing-list" : "#marketing-list-desktop"
        );
        const specialListStyle = getComputedStyle(specialListElem);
        specialHeight = Number(specialListStyle.height.slice(0, -2));
    }

    let baseMobileHeader = 0;
    let mobileHeaderSearchBarHeight = 0;
    if (isMobile) {
        // calculating mobile collapsed header height, includes alert height -- .head-menu-list-wrapper
        const collapsedHeader = document.querySelector(
            ".head-menu-list-wrapper"
        );
        const collapsedHeaderStyles = getComputedStyle(collapsedHeader);
        baseMobileHeader = Number(collapsedHeaderStyles.height.slice(0, -2));

        // calculating header search bar height (with is visible with full mobile header) -- .head-menu-name
        const mobileHeaderSearchBar = document.querySelector(".head-menu-name");
        const mobileHeaderSearchBarStyles = getComputedStyle(
            mobileHeaderSearchBar
        );
        mobileHeaderSearchBarHeight = Number(
            mobileHeaderSearchBarStyles.height.slice(0, -2)
        );
    }

    const baseDesktopHeader = 80;
    const elementPaddingTop = isMobile ? 0 : 16;
    const headerHeightMobile =
        isOneItem || isPrev
            ? baseMobileHeader + mobileHeaderSearchBarHeight
            : baseMobileHeader; // if scrolling previous item, then full header would be visible? so need to add mobileHeaderSearchBarHeight

    const scrollDataMobile = top - headerHeightMobile;
    const scrollDataDesktop = top - offset;
    const scrollData = isMobile ? scrollDataMobile : scrollDataDesktop;

    window &&
        document
            .getElementById("app")
            .scrollTo({ top: scrollData, behavior: "smooth" });
};
