export const menus = [
    {
        id: 1,
        name: "Starters",
    },
    {
        id: 2,
        name: "Salads",
    },
    {
        id: 3,
        name: "Pizza",
    },
    {
        id: 4,
        name: "Main Courses",
    },
    {
        id: 5,
        name: "Char Grill",
    },
    {
        id: 6,
        name: "Parfume Dessert",
    },
    {
        id: 7,
        name: "Desserts",
    },
    {
        id: 8,
        name: "Bar",
    },
];

export const categories = [
    {
        id: 1,
        name: "Starters",
        menuId: 1,
    },
    {
        id: 2,
        name: "Salads",
        menuId: 2,
    },
    {
        id: 3,
        name: "Pizza",
        menuId: 3,
    },
    {
        id: 4,
        name: "Main Courses",
        menuId: 4,
    },
    {
        id: 5,
        name: "Char Grill",
        menuId: 5,
    },
    {
        id: 6,
        name: "Parfume Dessert",
        menuId: 6,
    },
    {
        id: 7,
        name: "Desserts",
        menuId: 7,
    },
    // bar
    {
        id: 8,
        name: "Coctails",
        menuId: 8,
    },

    {
        id: 9,
        name: "Red Wines 0,75L",
        menuId: 8,
    },
    {
        id: 10,
        name: "Biodynamic Wines 0,75L",
        menuId: 8,
    },
    {
        id: 11,
        name: "White Wines 0,75L",
        menuId: 8,
    },
    {
        id: 12,
        name: "Rosé Wines 0,75L",
        menuId: 8,
    },
    {
        id: 121,
        name: "Red Wines 0,75L (Collector’s Selection)",
        menuId: 8,
    },
    {
        id: 111,
        name: "White Wines 0,75L (Collector’s Selection)",
        menuId: 8,
    },

   
   


  
    {
        id: 15,
        name: "Champagne",
        menuId: 8,
    },
    {
        id: 16,
        name: "Champagne rosé 0,75 L",
        menuId: 8,
    },
    {
        id: 17,
        name: "GRAPPA",
        menuId: 8,
    },
    {
        id: 18,
        name: "LIQUER",
        menuId: 8,
    },
    {
        id: 19,
        name: "RUM",
        menuId: 8,
    },
    {
        id: 20,
        name: "Whisky",
        menuId: 8,
    },
    {
        id: 21,
        name: "GIN",
        menuId: 8,
    },
    {
        id: 22,
        name: "VODKA",
        menuId: 8,
    },
    {
        id: 23,
        name: "COGNAC, CALVADOS, ARMAGNAC",
        menuId: 8,
    },
    {
        id: 24,
        name: "Tequila",
        menuId: 8,
    },
    {
        id: 25,
        name: "BEER",
        menuId: 8,
    },
    {
        id: 26,
        name: "COFFE",
        menuId: 8,
    },
    {
        id: 27,
        name: "TEA",
        menuId: 8,
    },
    {
        id: 28,
        name: "FRESH JUICE",
        menuId: 8,
    },
    {
        id: 29,
        name: "JUICE",
        menuId: 8,
    },
    {
        id: 30,
        name: "SOFT BEVERAGES",
        menuId: 8,
    },

    // end bar
];

export const dishes = [
    {
        id: 1,
        title: "BEEF CARPACCIO",

        imageUrl: "/images/image11.jpg",

        categoryId: 1,
        menuId: 1,
        minPrice: 38,
        maxPrice: 38,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Thinly sliced raw beef",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- arugula",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- truffle oil",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- Parmesan",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- capers",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                            {
                                name: "- possible sulfites (from truffle oil)",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 2,
        title: "BEETROOT CARPACCIO WITH PARMESAN & ARUGULA",

        imageUrl: "/images/image13.png",

        categoryId: 1,
        menuId: 1,
        minPrice: 32,
        maxPrice: 32,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Thinly sliced beetroot",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- arugula",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- Parmesan",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- walnuts",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- honey drizzle",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                            {
                                name: "- nuts",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 3,
        title: "PROSCIOTTO & MELON",

        imageUrl: "/images/image12.png",

        categoryId: 1,
        menuId: 1,
        minPrice: 28,
        maxPrice: 28,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Prosciutto di Parma",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- fresh melon slices",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- basil leaves",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- mozzarella di bufala",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 4,
        title: "SALMON TARTARE",

        imageUrl: "/images/image15.jpg",

        categoryId: 1,
        menuId: 1,
        minPrice: 34,
        maxPrice: 34,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Fresh diced salmon",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- avocado",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- balsamic pearls",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- lemon zest",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- microgreens",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Fish",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
  
    {
        id: 5,
        title: "TUNA TARTARE",

        imageUrl: "/images/image14.jpg",

        categoryId: 1,
        menuId: 1,
        minPrice: 36,
        maxPrice: 36,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Fresh diced tuna",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- truffle mayonnaise",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- cucumber ribbons",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- green herb oil",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Fish",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                            {
                                name: "- eggs",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 6,
        title: "BEEF TARTARE & TRUFFLE",

        imageUrl: "/images/image17.jpg",

        categoryId: 1,
        menuId: 1,
        minPrice: 38,
        maxPrice: 38,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Finely minced raw beef",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- truffle paste",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- egg yolk",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- capers",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- Dijon mustard",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Eggs",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 7,
        title: "PIKE CAVIAR WITH BLINY",

        imageUrl: "/images/image16.jpg",

        categoryId: 1,
        menuId: 1,
        minPrice: 85,
        maxPrice: 85,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- 100g pike caviar served on ice",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- dill crème fraîche",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- blinis",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Fish",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                            {
                                name: "- dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                            {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 8,
        title: "BLACK CAVIAR WITH BLINY",

        imageUrl: "/images/image21.jpg",

        categoryId: 1,
        menuId: 1,
        minPrice: 320,
        maxPrice: 320,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- 100g beluga caviar served on ice",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- crème fraîche",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- blinis",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Fish",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                            {
                                name: "- dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                            {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
  
    {
        id: 9,
        title: "BURRATA & TOMATOES",

        imageUrl: "/images/image19.jpg",

        categoryId: 2,
        menuId: 2,
        minPrice: 28,
        maxPrice: 28,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Fresh burrata cheese",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- heirloom tomatoes",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- basil",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- olive oil",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 10,
        title: "GARDEN HARMONY",

        imageUrl: "/images/image26.png",

        categoryId: 2,
        menuId: 2,
        minPrice: 26,
        maxPrice: 26,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Quinoa",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- roasted beetroot",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- baby spinach",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- avocado",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- pomegranate seeds",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- beetroot chips",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- lemon vinaigrette",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 11,
        title: "EXOTI SHRIMP SALAD",

        imageUrl: "/images/image22.jpg",

        categoryId: 2,
        menuId: 2,
        minPrice: 34,
        maxPrice: 34,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Mango slices",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- avocado",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- grilled shrimp",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- arugula",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- cherry tomatoes",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- fresh greens",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- lime dressing",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Shellfish",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 12,
        title: "GRILLED CALAMARI SALAD",

        imageUrl: "/images/image24.jpg",

        categoryId: 2,
        menuId: 2,
        minPrice: 32,
        maxPrice: 32,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Grilled calamari in garlic wine sauce",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- mixed greens",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- lemon vinaigrette",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Shellfish",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 13,
        title: "WARM VEAL SALAD",

        imageUrl: "/images/image27.jpg",

        categoryId: 2,
        menuId: 2,
        minPrice: 36,
        maxPrice: 36,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Roasted veal slices",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- cherry tomatoes",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- arugula",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- balsamic glaze",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 14,
        title: "TRUFFLE PIZZA",

        imageUrl: "/images/image29.jpg",

        categoryId: 3,
        menuId: 3,
        minPrice: 42,
        maxPrice: 42,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Creamy truffle sauce",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- mozzarella di bufala",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- black truffle slices",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- truffle oil",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            }, {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 15,
        title: "FIVE-CHEESE PIZZA",

        imageUrl: "/images/image31.jpg",

        categoryId: 3,
        menuId: 3,
        minPrice: 34,
        maxPrice: 34,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Creamy sauce base",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- Mozzarella",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- Gorgonzola",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- Parmesan",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- taleggio",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- Provolone",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- fresh basil",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- olive oil",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                            {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 16,
        title: "GORGONZOLA & PEAR PIZZA",

        imageUrl: "/images/image33.jpg",

        categoryId: 3,
        menuId: 3,
        minPrice: 32,
        maxPrice: 32,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Creamy sauce base",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- Gorgonzola",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- fresh pear slices",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- walnuts",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- honey",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- olive oil",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                            {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                            {
                                name: "- nuts",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 17,
        title: "RISOTTO WITH PRAWNS AND TRUFFLE",

        imageUrl: "/images/image35.jpg",

        categoryId: 4,
        menuId: 4,
        minPrice: 42,
        maxPrice: 42,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Arborio rice",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- shrimp",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- truffle paste",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- Parmesan",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Shellfish",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                            {
                                name: "- dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 18,
        title: "RISOTTO WITH MUSHROOMS",

        imageUrl: "/images/image36.jpg",

        categoryId: 4,
        menuId: 4,
        minPrice: 38,
        maxPrice: 38,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Arborio rice",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- wild porcini mushrooms",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- truffle paste",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- shallots",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 1880,
        title: "FETTUCCINE WITH PRAWNS & SPINACH",

        imageUrl: "/images/image37.jpg",

        categoryId: 4,
        menuId: 4,
        minPrice: 38,
        maxPrice: 38,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Fettuccine pasta",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- shrimp",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- spinach",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- creamy Parmesan sauce",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- cherry tomatoes",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Shellfish",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                            {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                            {
                                name: "- dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 19,
        title: "TAGLIATELLE ALLA BOLOGNESE",

        imageUrl: "/images/image38.jpg",

        categoryId: 4,
        menuId: 4,
        minPrice: 36,
        maxPrice: 36,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Handmade tortellini stuffed with beef",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- rich Bolognese sauce",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                            {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 20,
        title: "TAGLIOLINI WHIT TRUFFLE",

        imageUrl: "/images/image1.jpg",

        categoryId: 4,
        menuId: 4,
        minPrice: 44,
        maxPrice: 44,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Fresh tagliolini pasta",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- truffle cream sauce",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- freshly grated truffle",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                            {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 21,
        title: "FILET MIGNON WITH ASPARAGUS",

        imageUrl: "/images/image2.jpg",

        categoryId: 4,
        menuId: 4,
        minPrice: 58,
        maxPrice: 58,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Beef tenderloin",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- grilled asparagus",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- red wine reduction",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                 
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 22,
        title: "BEEF WELLINGTON",

        imageUrl: "/images/image3.jpg",

        categoryId: 4,
        menuId: 4,
        minPrice: 62,
        maxPrice: 62,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Tenderloin wrapped in puff pastry with mushrooms and pâté",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                            {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 23,
        title: "GRILLED OCTOPUS WITH POTATOES",

        imageUrl: "/images/image4.jpg",

        categoryId: 4,
        menuId: 4,
        minPrice: 54,
        maxPrice: 54,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Grilled octopus",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- roasted baby potatoes",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- olive oil",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Shellfish",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 24,
        title: "DORADO IN SALT CRUST FLAMBÉ",

        imageUrl: "/images/image5.jpg",

        categoryId: 4,
        menuId: 4,
        minPrice: 68,
        maxPrice: 68,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Whole dorado",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- salt crust",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- herbs",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- lemon",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Fish",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 25,
        title: "CAULIFLOWER STEAK & TRUFFLE SAUCE",

        imageUrl: "/images/image6.jpg",

        categoryId: 4,
        menuId: 4,
        minPrice: 28,
        maxPrice: 28,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Roasted cauliflower steak",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- truffle cream sauce",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 99,
                                required: false,
                            },
                          
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },

    // char grill

    {
        id: 26,
        title: "SEA BASS FILLET",

        categoryId: 5,
        menuId: 5,
        minPrice: 68,
        maxPrice: 68,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "SERVED WITH 2 SIDE DISHES OF YOUR CHOICE:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- TRUFFLE MASHED POTATOES OR",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- CLASSIC MASHED POTATOES",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- TRUFFLE RISOTTO",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- GRILLED SEASONAL VEGETABLES",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- FRESH GARDEN SALA",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- BUTTER-GLAZED CORN",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- SWEET POTATO FRIES",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- CLASSIC FRENCH FRIES (WITH TRUFFLE OPTION )",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 27,
        title: "SALMON FILLET",

        categoryId: 5,
        menuId: 5,
        minPrice: 65,
        maxPrice: 65,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "SERVED WITH 2 SIDE DISHES OF YOUR CHOICE:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- TRUFFLE MASHED POTATOES OR",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- CLASSIC MASHED POTATOES",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- TRUFFLE RISOTTO",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- GRILLED SEASONAL VEGETABLES",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- FRESH GARDEN SALA",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- BUTTER-GLAZED CORN",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- SWEET POTATO FRIES",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- CLASSIC FRENCH FRIES (WITH TRUFFLE OPTION )",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 28,
        title: "KING PRAWNS",

        categoryId: 5,
        menuId: 5,
        minPrice: 60,
        maxPrice: 60,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "SERVED WITH 2 SIDE DISHES OF YOUR CHOICE:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- TRUFFLE MASHED POTATOES OR",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- CLASSIC MASHED POTATOES",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- TRUFFLE RISOTTO",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- GRILLED SEASONAL VEGETABLES",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- FRESH GARDEN SALA",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- BUTTER-GLAZED CORN",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- SWEET POTATO FRIES",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- CLASSIC FRENCH FRIES (WITH TRUFFLE OPTION )",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },

    {
        id: 29,
        title: "RIBEYE STEAK",

        categoryId: 5,
        menuId: 5,
        minPrice: 68,
        maxPrice: 68,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "SERVED WITH 2 SIDE DISHES OF YOUR CHOICE:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- TRUFFLE MASHED POTATOES OR",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- CLASSIC MASHED POTATOES",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- TRUFFLE RISOTTO",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- GRILLED SEASONAL VEGETABLES",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- FRESH GARDEN SALA",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- BUTTER-GLAZED CORN",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- SWEET POTATO FRIES",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- CLASSIC FRENCH FRIES (WITH TRUFFLE OPTION )",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },

    // end char grill



    //parfume dessert start

    {
        id: 299,
        title: `MAISON FRANCIS KURKDJIAN “GENTLE FLUIDITY ”`,
        description:"The dessert is accompanied by a complimentary sample of the featured fragrance",
        imageUrl: "/images/image7.jpg",
        categoryId: 6,
        menuId: 6,
        minPrice: 75,
        maxPrice: 75,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Caramelized pear",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- almond biscuit",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- vanilla bean mousse",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- praline cre meux",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "-  roasted vanilla tuile",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                           
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Nuts",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- eggs",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                          
                           
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },

    {
        id: 30,
        title: `MAISON FRANCIS  KURKDJIAN “BACCARAT ROUGE 540”`,
        description:"The dessert is accompanied by a complimentary sample of the featured fragrance",
        imageUrl: "/images/image8.jpg",
        categoryId: 6,
        menuId: 6,
        minPrice: 75,
        maxPrice: 75,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Saffron biscuit",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- candied orange cream",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- almond mousse",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- spiced honey jelly",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- crispy praline layer",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                           
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Nuts",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- eggs",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                          
                           
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 31,
        title: `MAISON FRANCIS KURKDJIAN “A la Rose”`,
        description:"The dessert is accompanied by a complimentary sample of the featured fragrance",
        imageUrl: "/images/image9.jpg",
        categoryId: 6,
        menuId: 6,
        minPrice: 75,
        maxPrice: 75,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Rose jelly",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- pear coulis",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- lychee",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- almond biscuit",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- vanilla crémeux",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                           
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Nuts",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- eggs",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                          
                           
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },

    //parfume dessert end
    // default desserts start

    {
        id: 32,
        title: `Violet Dreams`,
         imageUrl: "/images/image10.jpg",
        categoryId: 7,
        menuId: 7,
        minPrice: 24,
        maxPrice: 24,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Vanilla custard",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- violet caramel sphere",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- edible gold flakes",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                       
                           
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- eggs",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        
                           
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 33,
        title: `TIRAMISU`,
         imageUrl: "/images/image18.jpg",
        categoryId: 7,
        menuId: 7,
        minPrice: 22,
        maxPrice: 22,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Mascarpone cream",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- espresso-soaked ladyfingers",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- cocoa powder",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                       
                           
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- eggs",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        
                           
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 34,
        title: `Ruby Bliss`,
         imageUrl: "/images/image20.jpg",
        categoryId: 7,
        menuId: 7,
        minPrice: 26,
        maxPrice: 26,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Cherry compote",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- raspberry gel",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- vanilla ice cream",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- vanilla cream",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },   {
                                name: "- sponge cake",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },   {
                                name: "- fresh berries",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                           
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- eggs",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                        
                           
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 35,
        title: `Rose Reverie`,
         imageUrl: "/images/image23.jpg",
        categoryId: 7,
        menuId: 7,
        minPrice: 32,
        maxPrice: 32,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Rose water mousse",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- longan gelée",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- almond sponge",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- white chocolate crisp",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },   {
                                name: "- isomalt rose petals",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },   
                           
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },   {
                                name: "- nuts",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- eggs",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                         
                           
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 36,
        title: `Mango Symphony`,
         imageUrl: "/images/image25.jpg",
        categoryId: 7,
        menuId: 7,
        minPrice: 28,
        maxPrice: 28,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Mango mousse",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- pineapple cream mousse",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- mango glaze",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- saffron sponge",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                           
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },   {
                                name: "- nuts",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- eggs",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                         
                           
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 37,
        title: `Peach Trilogy`,
         imageUrl: "/images/image28.jpg",
        categoryId: 7,
        menuId: 7,
        minPrice: 28,
        maxPrice: 28,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- White peach confit",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- roasted yellow peach",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- caramelized fig peach",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- vanilla cream",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                            {
                                name: "- peach mousse",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },     {
                                name: "- almond crumble",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },   
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },   {
                                name: "- nuts",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- eggs",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                         
                           
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 38,
        title: `Velvet Obsession`,
         imageUrl: "/images/image30.jpg",
        categoryId: 7,
        menuId: 7,
        minPrice: 32,
        maxPrice: 32,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dark chocolate mousse",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- Tonka bean cream",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- salted caramel",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- hazelnut praline",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                            {
                                name: "- cocoa nibs",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },     {
                                name: "- chocolate sphere with liquid chocolate center",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },   
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },   {
                                name: "- nuts",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                           
                            {
                                name: "- eggs",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                         
                           
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 39,
        title: `Golden Hive`,
         imageUrl: "/images/image32.jpg",
        categoryId: 7,
        menuId: 7,
        minPrice: 24,
        maxPrice: 24,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Honey sponge",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- sour cream frosting",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- vanilla ice cream",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- honey drizzle",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                          
                        ],
                    },
                    {
                        name: "Allergens:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Dairy",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },   {
                                name: "- gluten",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                           
                            {
                                name: "- eggs",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                         
                           
                        ],
                    },
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 40,
        title: `SORBET`,
       
        categoryId: 7,
        menuId: 7,
        minPrice: 14,
        maxPrice: 14,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Flavours:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- PINEAPPLE WITH CARDAMOM",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- MANGO",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- LEMON",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- CHERRY",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                          
                        ],
                    },
               
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 41,
        title: `MACARON`,
       
        categoryId: 7,
        menuId: 7,
        minPrice: 4,
        maxPrice: 4,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Flavours:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- LAVENDER",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- ROASTED VANILLA",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- ROSE",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- WOODLAND STRAWBERRY",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                            {
                                name: "- BLACKBERRY",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, {
                                name: "- MANGO",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                        ],
                    },
               
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    // default desserts end


    // coctails start
    {
        id: 42,
        title: `Lavender Elegance`,
       
        categoryId: 8,
        menuId: 8,
        minPrice: 18,
        maxPrice: 18,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Gin",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- lavender syrup",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- fresh lemon juice",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- lavender foam",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                           
                        ],
                    },
               
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 43,
        title: `Shining Aura`,
       
        categoryId: 8,
        menuId: 8,
        minPrice: 20,
        maxPrice: 20,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Vodka",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- Blue Curaçao",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- citrus blend",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- edible silver shimmer",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                           
                        ],
                    },
               
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 44,
        title: `Rose Whisper`,
       
        categoryId: 8,
        menuId: 8,
        minPrice: 22,
        maxPrice: 22,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Rose-infused gin",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- lychee liqueur",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- rose syrup",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- sparkling rosé wine",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                            {
                                name: "- rose petals",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                        ],
                    },
               
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 45,
        title: `Peach Serenade`,
       
        categoryId: 8,
        menuId: 8,
        minPrice: 18,
        maxPrice: 18,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Peach purée",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- bourbon",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- vanilla essence",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- orange bitters",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                           
                        ],
                    },
               
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 46,
        title: `Golden Horizon`,
       
        categoryId: 8,
        menuId: 8,
        minPrice: 20,
        maxPrice: 20,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Aged rum",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- pineapple juice",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- coconut cream",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- edible gold dust",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                           
                        ],
                    },
               
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 47,
        title: `Crimson Velvet`,
       
        categoryId: 8,
        menuId: 8,
        minPrice: 19,
        maxPrice: 19,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Red wine reduction",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- spiced rum",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- cranberry juice",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- cinnamon foam",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                           
                        ],
                    },
               
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 48,
        title: `Emerald Blossom`,
       
        categoryId: 8,
        menuId: 8,
        minPrice: 18,
        maxPrice: 18,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Basil-infused vodka",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- elderflower liqueur",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- fresh cucumber juice",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- lime",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                           
                        ],
                    },
               
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 49,
        title: `Celestial Pearl`,
       
        categoryId: 8,
        menuId: 8,
        minPrice: 24,
        maxPrice: 24,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Champagne",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- gin",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- grapefruit reduction",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- edible pearls",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                           
                        ],
                    },
               
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 50,
        title: `Midnight Alchemy`,
       
        categoryId: 8,
        menuId: 8,
        minPrice: 22,
        maxPrice: 22,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Black vodka",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- activated charcoal",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- blackberry purée",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- ginger liqueur",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                           
                        ],
                    },
               
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    {
        id: 51,
        title: `Smoky Amour`,
       
        categoryId: 8,
        menuId: 8,
        minPrice: 20,
        maxPrice: 20,
        dishVariants: [
            {
                modifierGroups: [
                    {
                        name: "Ingredients:",
                        defaultAmount: 0,
                        minAmount: 0,
                        maxAmount: 99,
                        freeAmount: 0,
                        required: false,
                        modifiers: [
                            {
                                name: "- Mezcal",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- dark chocolate liqueur",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- espresso",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            },
                            {
                                name: "- smoked rosemary",
                                price: 0,
                                defaultAmount: 0,
                                minAmount: 0,
                                maxAmount: 1,
                                required: false,
                            }, 
                           
                        ],
                    },
               
                ],

                active: true,
            },
        ],
        hasModifiers: true,
        active: true,
    },
    // coctails end
    

    // red wines 0.75l start

    
    {
        id: 52,
        title: `Domaine La Garrigue Côtes du Rhône 2021`,
        description:"France",
        categoryId: 9,
        menuId: 8,
        minPrice: 45,
        maxPrice: 45,
       
        hasModifiers: false,
        active: true,
    },{
        id: 53,
        title: `Castello di Volpaia Chianti Classico 2020`,
        description:"Italy",
        categoryId: 9,
        menuId: 8,
        minPrice: 60,
        maxPrice: 70,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 54,
        title: `Catena Zapata “Adrianna Vineyard” Malbec 2019`,
        description:"Argentina",
        categoryId: 9,
        menuId: 8,
        minPrice: 260,
        maxPrice: 260,
       
        hasModifiers: false,
        active: true,
    },   {
        id: 55,
        title: `Opus One 2018`,
        description:"USA",
        categoryId: 9,
        menuId: 8,
        minPrice: 740,
        maxPrice: 740,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 56,
        title: `Château Trotanoy 2015`,
        description:"France",
        categoryId: 9,
        menuId: 8,
        minPrice: 580,
        maxPrice: 580,
       
        hasModifiers: false,
        active: true,
    },  {
        id: 57,
        title: `Château Margaux 2015`,
        description:"France",
        categoryId: 9,
        menuId: 8,
        minPrice: 1850,
        maxPrice: 1850,
       
        hasModifiers: false,
        active: true,
    },{
        id: 58,
        title: `Masseto 2016`,
        description:"Italy",
        categoryId: 9,
        menuId: 8,
        minPrice: 1480,
        maxPrice: 1480,
       
        hasModifiers: false,
        active: true,
    },
    // red wines 0.75l end



    // start Biodynamic Wines 0,75L 

    {
        id: 58,
        title: `Nikolaihof Riesling “Vinothek” 2002`,
        description:"Italy",
        categoryId: 10,
        menuId: 8,
        minPrice: 430,
        maxPrice: 430,
       
        hasModifiers: false,
        active: true,
    }, {
        id: 58,
        title: `Château Pontet-Canet 2015`,
        description:"France",
        categoryId: 10,
        menuId: 8,
        minPrice: 274,
        maxPrice: 274,
       
        hasModifiers: false,
        active: true,
    },



    //end Biodynamic Wines 0,75L



    // start White Wines 0,75L

    {
        id: 588,
        title: `Louis Jadot Mâcon-Villages 2021`,
        description:"France",
        categoryId: 11,
        menuId: 8,
        minPrice: 40,
        maxPrice: 40,
       
        hasModifiers: false,
        active: true,
    },

    {
        id: 59,
        title: `Santa Margherita Pinot Grigio Alto Adige 2021`,
        description:"Italy",
        categoryId: 11,
        menuId: 8,
        minPrice: 50,
        maxPrice: 50,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 60,
        title: `William Fèvre Chablis Premier Cru “Vaillons” 2020`,
        description:"France",
        categoryId: 11,
        menuId: 8,
        minPrice: 94,
        maxPrice: 94,
       
        hasModifiers: false,
        active: true,
    },  {
        id: 61,
        title: `Ornellaia “Poggio alle Gazze” 2020`,
        description:"Italy",
        categoryId: 11,
        menuId: 8,
        minPrice: 150,
        maxPrice: 150,
       
        hasModifiers: false,
        active: true,
    }, {
        id: 62,
        title: `Puligny-Montrachet Premier Cru “Les Folatières” 2018`,
        description:"France",
        categoryId: 11,
        menuId: 8,
        minPrice: 280,
        maxPrice: 280,
       
        hasModifiers: false,
        active: true,
    },{
        id: 63,
        title: `Château d’Yquem 2015`,
        description:"France",
        categoryId: 11,
        menuId: 8,
        minPrice: 1110,
        maxPrice: 1110,
       
        hasModifiers: false,
        active: true,
    },
    // end White Wines 0,75L

    //start Rosé Wines 0,75L
    {
        id: 64,
        title: `Domaines Ott “Château de Selle” Rosé 2019`,
        description:"France",
        categoryId: 12,
        menuId: 8,
        minPrice: 84,
        maxPrice: 84,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 65,
        title: `Château d’Esclans “Garrus” Rosé 2018`,
        description:"France",
        categoryId: 12,
        menuId: 8,
        minPrice: 194,
        maxPrice: 194,
       
        hasModifiers: false,
        active: true,
    },


    // end Rosé Wines 0,75L



    // start Red Wines 0,75L (Collector’s Selection)

    {
        id: 66,
        title: `Penfolds Grange 2014`,
        description:"Australia",
        categoryId: 121,
        menuId: 8,
        minPrice: 1100,
        maxPrice: 1100,
       
        hasModifiers: false,
        active: true,
    }, {
        id: 67,
        title: `Château Lafite Rothschild 2005`,
        description:"France",
        categoryId: 121,
        menuId: 8,
        minPrice: 1600,
        maxPrice: 1600,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 68,
        title: `Château Le Pin 2015`,
        description:"France",
        categoryId: 121,
        menuId: 8,
        minPrice: 2500,
        maxPrice: 2500,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 69,
        title: `Château Pétrus 2015`,
        description:"France",
        categoryId: 121,
        menuId: 8,
        minPrice: 6000,
        maxPrice: 6000,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 70,
        title: `Romanée-Conti, Domaine de la Romanée-Conti 2010`,
        description:"France",
        categoryId: 121,
        menuId: 8,
        minPrice: 24500,
        maxPrice: 24500,
       
        hasModifiers: false,
        active: true,
    },
    //end Red Wines 0,75L (Collector’s Selection)


    // start White Wines 0,75L (Collector’s Selection)

    {
        id: 71,
        title: `Château Haut-Brion Blanc 2015`,
        description:"France",
        categoryId: 111,
        menuId: 8,
        minPrice: 1370,
        maxPrice: 1370,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 72,
        title: `Domaine Leflaive Chevalier-Montrachet Grand Cru 2017`,
        description:"France",
        categoryId: 111,
        menuId: 8,
        minPrice: 2708,
        maxPrice: 2708,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 73,
        title: `Domaine Leflaive Montrachet Grand Cru 2016`,
        description:"France",
        categoryId: 111,
        menuId: 8,
        minPrice: 2500,
        maxPrice: 2500,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 74,
        title: `Egon Müller Scharzhofberger Riesling Trockenbeerenauslese 2011`,
        description:"Germany",
        categoryId: 111,
        menuId: 8,
        minPrice: 7800,
        maxPrice: 7800,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 75,
        title: `Domaine de la Romanée-Conti Montrachet Grand Cru 2018`,
        description:"France",
        categoryId: 111,
        menuId: 8,
        minPrice: 14175,
        maxPrice: 14175,
       
        hasModifiers: false,
        active: true,
    },
    //end White Wines 0,75L (Collector’s Selection)


    // start Champagne 0,75L
    {
        id: 76,
        title: `POL ROGER BRUT RÉSERVE`,
       
        categoryId: 15,
        menuId: 8,
        minPrice: 135,
        maxPrice: 135,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 77,
        title: `RUINART BRUT`,
       
        categoryId: 15,
        menuId: 8,
        minPrice: 135,
        maxPrice: 135,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 78,
        title: `RUINART BLANC DE BLANCS`,
       
        categoryId: 15,
        menuId: 8,
        minPrice: 165,
        maxPrice: 165,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 79,
        title: `BRUNO PAILLARD PREMIÈRE CUVÉE`,
       
        categoryId: 15,
        menuId: 8,
        minPrice: 129,
        maxPrice: 129,
       
        hasModifiers: false,
        active: true,
    },  {
        id: 80,
        title: `ROEDERER BRUT PREMIER`,
       
        categoryId: 15,
        menuId: 8,
        minPrice: 135,
        maxPrice: 135,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 81,
        title: `BILLECART-SALMON BRUT RÉSERVE`,
       
        categoryId: 15,
        menuId: 8,
        minPrice: 144

        ,
        maxPrice: 144

        ,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 82,
        title: `GOSSET GRANDE RÉSERVE BRUT`,
       
        categoryId: 15,
        menuId: 8,
        minPrice: 130     ,
        maxPrice: 130  ,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 83,
        title: `BOLLINGER BRUT SPECIAL CUVÉE`,
       
        categoryId: 15,
        menuId: 8,
        minPrice: 160     ,
        maxPrice: 160  ,
       
        hasModifiers: false,
        active: true,
    }, {
        id: 84,
        title: `DOM PÉRIGNON VINTAGE 2013`,
       
        categoryId: 15,
        menuId: 8,
        minPrice: 395     ,
        maxPrice: 395  ,
       
        hasModifiers: false,
        active: true,
    },{
        id: 85,
        title: `POL ROGER SIR WINSTON CHURCHILL 2013`,
       
        categoryId: 15,
        menuId: 8,
        minPrice: 410     ,
        maxPrice: 410  ,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 86,
        title: `KRUG GRANDE CUVÉE BRUT`,
       
        categoryId: 15,
        menuId: 8,
        minPrice: 410     ,
        maxPrice: 410  ,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 87,
        title: `ROEDERER CRISTAL 2014`,
       
        categoryId: 15,
        menuId: 8,
        minPrice: 480     ,
        maxPrice: 480  ,
       
        hasModifiers: false,
        active: true,
    },
    //end Champagne 0,75L


    // start Champagne rosé 0,75 L

    {
        id: 877,
        title: `RUINART ROSÉ`,
       
        categoryId: 16,
        menuId: 8,
        minPrice: 155     ,
        maxPrice: 155  ,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 88,
        title: `BILLECART-SALMON ROSÉ`,
       
        categoryId: 16,
        menuId: 8,
        minPrice: 179     ,
        maxPrice: 179  ,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 89,
        title: `BOLLINGER ROSÉ LA GRANDE ANNÉE 2014`,
       
        categoryId: 16,
        menuId: 8,
        minPrice: 300     ,
        maxPrice: 300  ,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 90,
        title: `LAURENT PERRIER ROSÉ`,
       
        categoryId: 16,
        menuId: 8,
        minPrice: 200     ,
        maxPrice: 200  ,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 91,
        title: `TAITTINGER COMTES ROSÉ 2008`,
       
        categoryId: 16,
        menuId: 8,
        minPrice: 400     ,
        maxPrice: 400  ,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 92,
        title: `KRUG ROSÉ`,
       
        categoryId: 16,
        menuId: 8,
        minPrice: 620     ,
        maxPrice: 620  ,
       
        hasModifiers: false,
        active: true,
    },
    {
        id: 93,
        title: `ROEDERER CRISTAL ROSÉ 2012`,
       
        categoryId: 16,
        menuId: 8,
        minPrice: 700     ,
        maxPrice: 700  ,
       
        hasModifiers: false,
        active: true,
    },
    // end Champagne rosé 0,75 L


    // start GRAPPA

    {
        id: 94,
        title: `SARPA DI POLI RISERVA`,
       
        categoryId: 17,
        menuId: 8,
        minPrice: 14     ,
        maxPrice: 14  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    {
        id: 95,
        title: `PO DI POLI MORBIDA MOSCATO`,
       
        categoryId: 17,
        menuId: 8,
        minPrice: 16     ,
        maxPrice: 16  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    {
        id: 96,
        title: `GAJA COSTA RUSSI`,
       
        categoryId: 17,
        menuId: 8,
        minPrice: 20     ,
        maxPrice: 20  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    {
        id: 97,
        title: `BISERNO `,
       
        categoryId: 17,
        menuId: 8,
        minPrice: 18     ,
        maxPrice: 18  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    {
        id: 98,
        title: `MARZADRO AFFINATA GEWÜRZTRAMINER `,
       
        categoryId: 17,
        menuId: 8,
        minPrice: 16     ,
        maxPrice: 16  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    }, {
        id: 99,
        title: `NONINO LA MALVASIA`,
       
        categoryId: 17,
        menuId: 8,
        minPrice: 18     ,
        maxPrice: 18  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 100,
        title: `ORNELLAIA`,
       
        categoryId: 17,
        menuId: 8,
        minPrice: 22     ,
        maxPrice: 22  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 101,
        title: `SARPA DI POLI RISERVA`,
       
        categoryId: 17,
        menuId: 8,
        minPrice: 14     ,
        maxPrice: 14  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 102,
        title: `TIGNANELLO`,
       
        categoryId: 17,
        menuId: 8,
        minPrice: 25     ,
        maxPrice: 25  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 103,
        title: `BERTA TRE SOLI TRE`,
       
        categoryId: 17,
        menuId: 8,
        minPrice: 28     ,
        maxPrice: 28  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    // end GRAPPA


    // start LIQUER

    {
        id: 104,
        title: `JÄGERMEISTER`,
       
        categoryId: 18,
        menuId: 8,
        minPrice: 8     ,
        maxPrice: 8  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    {
        id: 104,
        title: `AMARETTO`,
       
        categoryId: 18,
        menuId: 8,
        minPrice: 10     ,
        maxPrice: 10  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    {
        id: 104,
        title: `GRAND MARNIER CORDON ROUGE`,
       
        categoryId: 18,
        menuId: 8,
        minPrice: 14     ,
        maxPrice: 14  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    }, {
        id: 104,
        title: `DRAMBUIE`,
       
        categoryId: 18,
        menuId: 8,
        minPrice: 12     ,
        maxPrice: 12  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    }, {
        id: 104,
        title: `ST. GERMAIN 4 CL FRANGELICO`,
       
        categoryId: 18,
        menuId: 8,
        minPrice: 14     ,
        maxPrice: 14  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 104,
        title: `ITALICUS ROSOLIO DI BERGAMOTTO`,
       
        categoryId: 18,
        menuId: 8,
        minPrice: 16     ,
        maxPrice: 16  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 104,
        title: `LIMONCELLO`,
       
        categoryId: 18,
        menuId: 8,
        minPrice: 10     ,
        maxPrice: 10  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    // end LIQUER


    // start RUM

    {
        id: 105,
        title: `ZACAPA XO 25 Y GUATEMALA`,
       
        categoryId: 19,
        menuId: 8,
        minPrice: 28     ,
        maxPrice: 28  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    {
        id: 106,
        title: `RON ABUELO 12 Y PANAMA`,
       
        categoryId: 19,
        menuId: 8,
        minPrice: 12     ,
        maxPrice: 12  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },  {
        id: 107,
        title: `APPLETON ESTATE 21 Y JAMAICA`,
       
        categoryId: 19,
        menuId: 8,
        minPrice: 24     ,
        maxPrice: 24  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    }, {
        id: 108,
        title: `DIPLOMATICO RESERVA VENEZUELA`,
       
        categoryId: 19,
        menuId: 8,
        minPrice: 14     ,
        maxPrice: 14  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    }, {
        id: 109,
        title: `DIPLOMATICO VINTAGE VENEZUELA`,
       
        categoryId: 19,
        menuId: 8,
        minPrice: 28     ,
        maxPrice: 28  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 110,
        title: `DIPLOMATICO AMBASSADOR VENEZUELA`,
       
        categoryId: 19,
        menuId: 8,
        minPrice: 40     ,
        maxPrice: 40  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    //end RUM


    // start Whisky
    {
        id: 111,
        title: `JOHNNIE WALKER GOLD LABEL SCOTLAND`,
       
        categoryId: 20,
        menuId: 8,
        minPrice: 16     ,
        maxPrice: 16  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    {
        id: 112,
        title: `JOHNNIE WALKER PLATINUM LABEL SCOTLAND`,
       
        categoryId: 20,
        menuId: 8,
        minPrice: 18     ,
        maxPrice: 18  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    {
        id: 113,
        title: `JOHNNIE WALKER BLUE LABEL SCOTLAND`,
       
        categoryId: 20,
        menuId: 8,
        minPrice: 28     ,
        maxPrice: 28  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    }, {
        id: 114,
        title: `JOHNNIE WALKER KING GEORGE SCOTLAND`,
       
        categoryId: 20,
        menuId: 8,
        minPrice: 70     ,
        maxPrice: 70  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    }, {
        id: 115,
        title: `CHIVAS REGAL 18 Y SCOTLAND`,
       
        categoryId: 20,
        menuId: 8,
        minPrice: 16     ,
        maxPrice: 16  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    }, {
        id: 116,
        title: `MONKEY SHOULDER SCOTLAND`,
       
        categoryId: 20,
        menuId: 8,
        minPrice: 12     ,
        maxPrice: 12  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    }, {
        id: 117,
        title: `ROE & CO IRELAND`,
       
        categoryId: 20,
        menuId: 8,
        minPrice: 12     ,
        maxPrice: 12  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    }, {
        id: 118,
        title: `RED BREAST 12 Y IRELAND`,
       
        categoryId: 20,
        menuId: 8,
        minPrice: 14     ,
        maxPrice: 14  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    }, {
        id: 119,
        title: `NIKKA 12 Y JAPAN`,
       
        categoryId: 20,
        menuId: 8,
        minPrice: 22     ,
        maxPrice: 22  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 120,
        title: `NIKKA FROM THE BARREL JAPAN`,
       
        categoryId: 20,
        menuId: 8,
        minPrice: 20     ,
        maxPrice: 20  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    {
        id: 121,
        title: `KNOB CREEK UNITED STATES`,
       
        categoryId: 20,
        menuId: 8,
        minPrice: 14     ,
        maxPrice: 14  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 122,
        title: `WHISTLE PIG 10 Y UNITED STATES`,
       
        categoryId: 20,
        menuId: 8,
        minPrice: 24     ,
        maxPrice: 24  ,
       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 123,
        title: `WHISTLE PIG 15 Y UNITED STATES`,
       
        categoryId: 20,
        menuId: 8,
        minPrice: 40     ,
        maxPrice: 40  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    // end Whisky


    //start GIN
    {
        id: 124,
        title: `TANQUERAY NO.10 ENGLAND`,
       
          categoryId: 21,
        menuId: 8,
        minPrice: 12     ,
        maxPrice: 12  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 125,
        title: `HENDRICK'S SCOTLAND`,
       
          categoryId: 21,
        menuId: 8,
        minPrice: 14     ,
        maxPrice: 14  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    {
        id: 126,
        title: `MONKEY 47 GERMANY`,
       
          categoryId: 21,
        menuId: 8,
        minPrice: 16     ,
        maxPrice: 16  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    {
        id: 127,
        title: `SIPSMITH ENGLAND`,
       
          categoryId: 21,
        menuId: 8,
        minPrice: 14     ,
        maxPrice: 14  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 128,
        title: `BLUE GIN AUSTRIA`,
       
          categoryId: 21,
        menuId: 8,
        minPrice: 12     ,
        maxPrice: 12  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 129,
        title: `RICK GIN AUSTRIA`,
       
          categoryId: 21,
        menuId: 8,
        minPrice: 12     ,
        maxPrice: 12  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 130,
        title: `GIN MARE SPAIN`,
       
          categoryId: 21,
        menuId: 8,
        minPrice: 14     ,
        maxPrice: 14  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    // end GIN




    // start VODKA
    {
        id: 131,
        title: `KETEL ONE NETHERLANDS`,
       
          categoryId: 22,
        menuId: 8,
        minPrice: 12     ,
        maxPrice: 12  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    }, {
        id: 132,
        title: `ABSOLUT ELYX`,
       
          categoryId: 22,
        menuId: 8,
        minPrice: 14     ,
        maxPrice: 14  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    {
        id: 133,
        title: `BELVEDERE POLAND`,
       
          categoryId: 22,
        menuId: 8,
        minPrice: 16     ,
        maxPrice: 16  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    {
        id: 134,
        title: `GREY GOOSE FRANCE`,
       
          categoryId: 22,
        menuId: 8,
        minPrice: 16     ,
        maxPrice: 16  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    {
        id: 135,
        title: `CHOPIN POTATO POLAND`,
       
          categoryId: 22,
        menuId: 8,
        minPrice: 14     ,
        maxPrice: 14  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    // end VODKA

    // start COGNAC, CALVADOS, ARMAGNAC

    {
        id: 136,
        title: `HENNESSY XO`,
       
         categoryId: 23,
        menuId: 8,
        minPrice: 38     ,
        maxPrice: 38  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    {
        id: 137,
        title: `HENNESSY PARADIS`,
       
         categoryId: 23,
        menuId: 8,
        minPrice: 90     ,
        maxPrice: 90  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 138,
        title: `REMY MARTIN LOUIS XIII`,
       
         categoryId: 23,
        menuId: 8,
        minPrice: 280     ,
        maxPrice: 280  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 139,
        title: `REMY MARTIN XO`,
       
         categoryId: 23,
        menuId: 8,
        minPrice: 40     ,
        maxPrice: 40  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 140,
        title: `JEANNEAU VSOP`,
       
         categoryId: 23,
        menuId: 8,
        minPrice: 18     ,
        maxPrice: 18  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 141,
        title: `BOULARD GRAND SOLAGE `,
       
         categoryId: 23,
        menuId: 8,
        minPrice: 14     ,
        maxPrice: 14  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    // end COGNAC, CALVADOS, ARMAGNAC


    // start Tequila

    {
        id: 142,
        title: `DON JULIO 1942`,
       
          categoryId: 24,
        menuId: 8,
        minPrice: 28     ,
        maxPrice: 28  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    {
        id: 143,
        title: `DON JULIO BLANCO, ANEJO`,
       
          categoryId: 24,
        menuId: 8,
        minPrice: 14     ,
        maxPrice: 14  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    }, {
        id: 144,
        title: `ALIPÚS SAN ANDRÉS`,
       
          categoryId: 24,
        menuId: 8,
        minPrice: 12     ,
        maxPrice: 12  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 145,
        title: `AMOR MIO BLANCO`,
       
          categoryId: 24,
        menuId: 8,
        minPrice: 16     ,
        maxPrice: 16  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 146,
        title: `AMOR MIO REPOSADO`,
       
          categoryId: 24,
        menuId: 8,
        minPrice: 28     ,
        maxPrice: 28  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 147,
        title: `AMOR MIO RESERVA`,
       
          categoryId: 24,
        menuId: 8,
        minPrice: 46     ,
        maxPrice: 46  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 148,
        title: `CLASE AZUL BLANCO`,
       
          categoryId: 24,
        menuId: 8,
        minPrice: 20     ,
        maxPrice: 20  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 149,
        title: `CLASE AZUL REPOSADO`,
       
          categoryId: 24,
        menuId: 8,
        minPrice: 32     ,
        maxPrice: 32  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 150,
        title: `CODIGO 1530 BLANCO`,
       
          categoryId: 24,
        menuId: 8,
        minPrice: 12     ,
        maxPrice: 12  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },{
        id: 151,
        title: `VOLCAN BLANCO`,
       
          categoryId: 24,
        menuId: 8,
        minPrice: 12     ,
        maxPrice: 12  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:4
        }]
    },
    // end Tequila



    // start BEER

    {
        id: 152,
        title: `ZWETTLER SAPHIR`,
       
         categoryId: 25,
        menuId: 8,
        minPrice: 7     ,
        maxPrice: 7  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"L",
            amount:0.33
        }]
    }, {
        id: 153,
        title: `GRIESKIRCHNER DUNKEL`,
       
         categoryId: 25,
        menuId: 8,
        minPrice: 7     ,
        maxPrice: 7  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"L",
            amount:0.33
        }]
    }, {
        id: 154,
        title: `BECK'S ALKOHOLFREI`,
       
         categoryId: 25,
        menuId: 8,
        minPrice: 6     ,
        maxPrice: 6  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"L",
            amount:0.33
        }]
    },

    // end BEER



    // start COFFE
    {
        id: 155,
        title: `ESPRESSO`,
       
        categoryId: 26,
        menuId: 8,
        minPrice: 4.50     ,
        maxPrice: 4.50  ,       
        hasModifiers: false,
        active: true,
      
    },
    {
        id: 156,
        title: `DOPPIO`,
       
        categoryId: 26,
        menuId: 8,
        minPrice: 7.5     ,
        maxPrice: 7.5  ,       
        hasModifiers: false,
        active: true,
      
    }, {
        id: 157,
        title: `CAFFÈ LATTE`,
       
        categoryId: 26,
        menuId: 8,
        minPrice: 7     ,
        maxPrice: 7  ,       
        hasModifiers: false,
        active: true,
      
    }, {
        id: 158,
        title: `CAPPUCCINO`,
       
        categoryId: 26,
        menuId: 8,
        minPrice: 8     ,
        maxPrice: 8  ,       
        hasModifiers: false,
        active: true,
      
    },
    

    // end  COFFE


    // start TEA

    {
        id: 158,
        title: `BLACK TEA`,
       
        categoryId: 27,
        menuId: 8,
        minPrice: 7     ,
        maxPrice: 7  ,       
        hasModifiers: false,
        active: true,
      
    },
    {
        id: 159,
        title: `BLACK TEA WITH BERGAMOT`,
       
        categoryId: 27,
        menuId: 8,
        minPrice: 7     ,
        maxPrice: 7  ,       
        hasModifiers: false,
        active: true,
      
    }, {
        id: 160,
        title: `GREEN TEA`,
       
        categoryId: 27,
        menuId: 8,
        minPrice: 7     ,
        maxPrice: 7  ,       
        hasModifiers: false,
        active: true,
      
    }, {
        id: 161,
        title: `JASMINE TEA`,
       
        categoryId: 27,
        menuId: 8,
        minPrice: 7     ,
        maxPrice: 7  ,       
        hasModifiers: false,
        active: true,
      
    }, {
        id: 162,
        title: `PEACH TEA`,
       
        categoryId: 27,
        menuId: 8,
        minPrice: 7     ,
        maxPrice: 7  ,       
        hasModifiers: false,
        active: true,
      
    }, {
        id: 163,
        title: `ROSE TEA`,
       
        categoryId: 27,
        menuId: 8,
        minPrice: 7     ,
        maxPrice: 7  ,       
        hasModifiers: false,
        active: true,
      
    }, {
        id: 164,
        title: `MINT TEA`,
       
        categoryId: 27,
        menuId: 8,
        minPrice: 7     ,
        maxPrice: 7  ,       
        hasModifiers: false,
        active: true,
      
    }, {
        id: 165,
        title: `HERBAL TEA`,
       
        categoryId: 27,
        menuId: 8,
        minPrice: 7     ,
        maxPrice: 7  ,       
        hasModifiers: false,
        active: true,
      
    }, {
        id: 166,
        title: `CHAMOMILE TEA`,
       
        categoryId: 27,
        menuId: 8,
        minPrice: 7     ,
        maxPrice: 7  ,       
        hasModifiers: false,
        active: true,
      
    },

    // end TEA



    // start FRESH JUICE
    {
        id: 167,
        title: `APPLE FRESH JUICE`,
       
        categoryId: 28,
        menuId: 8,
        minPrice: 10     ,
        maxPrice: 10  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:25
        }]
      
    }, {
        id: 168,
        title: `APPLE & CARROT FRESH JUICE`,
       
        categoryId: 28,
        menuId: 8,
        minPrice: 10     ,
        maxPrice: 10  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:25
        }]
      
    },
    {
        id: 169,
        title: `ORANGE FRESH JUICE`,
       
        categoryId: 28,
        menuId: 8,
        minPrice: 12     ,
        maxPrice: 12  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:25
        }]
      
    },

    // end FRESH JUICE

    // start JUICE
    {
        id: 170,
        title: `PINEAPPLE JUICE`,
       
        categoryId: 29,
        menuId: 8,
        minPrice: 6     ,
        maxPrice: 6  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:25
        }]
      
    }, {
        id: 171,
        title: `PEACH JUICE `,
       
        categoryId: 29,
        menuId: 8,
        minPrice: 6     ,
        maxPrice: 6  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:25
        }]
      
    }, {
        id: 172,
        title: `APPLE JUICE`,
       
        categoryId: 29,
        menuId: 8,
        minPrice: 6     ,
        maxPrice: 6  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:25
        }]
      
    }, {
        id: 173,
        title: `MANGO JUICE`,
       
        categoryId: 29,
        menuId: 8,
        minPrice: 6     ,
        maxPrice: 6  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:25
        }]
      
    }, {
        id: 174,
        title: `ORANGE JUICE`,
       
        categoryId: 29,
        menuId: 8,
        minPrice: 6     ,
        maxPrice: 6  ,       
        hasModifiers: false,
        active: true,
        dishVariants: [{
            unit:"cl",
            amount:25
        }]
      
    },


    // end JUICE


    // start SOFT BEVERAGES

    {
        id: 175,
        title: `FENTIMANS CURIOSITY COLA`,
       
        categoryId: 30,
        menuId: 8,
        minPrice: 8     ,
        maxPrice: 8  ,       
        hasModifiers: false,
        active: true,
       
      
    },
    {
        id: 176,
        title: `FENTIMANS CHERRY CO`,
       
        categoryId: 30,
        menuId: 8,
        minPrice: 9     ,
        maxPrice: 9  ,       
        hasModifiers: false,
        active: true,
       
      
    },

    {
        id: 178,
        title: `FENTIMANS ROSE LEMONADE`,
       
        categoryId: 30,
        menuId: 8,
        minPrice: 9     ,
        maxPrice: 9  ,       
        hasModifiers: false,
        active: true,
       
      
    },
    {
        id: 179,
        title: `FENTIMANS TONIC WATER`,
       
        categoryId: 30,
        menuId: 8,
        minPrice: 8     ,
        maxPrice: 8  ,       
        hasModifiers: false,
        active: true,
       
      
    }, {
        id: 180,
        title: `PEACH ICE TEA`,
       
        categoryId: 30,
        menuId: 8,
        minPrice: 10     ,
        maxPrice: 10  ,       
        hasModifiers: false,
        active: true,
       
      
    }, {
        id: 181,
        title: `LEMON ICE TEA `,
       
        categoryId: 30,
        menuId: 8,
        minPrice: 10     ,
        maxPrice: 10  ,       
        hasModifiers: false,
        active: true,
       
      
    },

    //end SOFT BEVERAGES
];
