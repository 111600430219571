import { makeAutoObservable } from "mobx";

class Store {
    menuId = 1;
    categoryActive = 1;
    modalMenuActive = false;
    modalFoodActive = -1;
    searchModalActive = false;
    dishesMobx="[]";
    constructor() {
        makeAutoObservable(this);
    }

    getCurrentMenuId = () => {
        return this.menuId;
    };
    getCategoryActiveId = () => {
        return this.menuId;
    };
    setCurrentMenuId = (id) => {
        this.menuId = id;
    };

    setCurrentCategoryActive = (id) => {
        this.categoryActive = id;
    };
    setModalMenuActive = (value) => {
        this.modalMenuActive = value;
        console.log(this.modalMenuActive);
        
    };
    setModalFoodActive = (value) => {
        this.modalFoodActive = value;
    };
    setSearchModalActive = (value) => {
        this.searchModalActive = value;
    };
    setDishesModal=(value)=>{
        this.dishesMobx=value;
    }
}

export default new Store();
