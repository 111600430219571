import React from "react";
import { getMenu } from "../../helpers/helpers";
import { Link, useNavigate } from "react-router-dom";
import mobxStore from "../../mobx/mobxStore";

const RestaurantMenuSections=()=>{
    const {setCurrentMenuId}=mobxStore;

    const menus=getMenu();
    const navigate=useNavigate();
        return (      <div
                data-v-11f3e009
                className="restaurant-menu "
                data-testid="restaurantMenuSections"
            >
                <h2 data-v-11f3e009 className="res-menu">Menu sections:</h2>
                <div data-v-207fadbf data-v-11f3e009 className="main-menu">
                    {menus.map((menu)=>  <span
                        data-v-207fadbf
                       
                        className="main-menu-item"
                        data-testid={`menuSection-${menu.id}`}
                        onClick={()=>{setCurrentMenuId(menu.id);navigate("/menu")}}
                    >
                        <div data-v-207fadbf className="item--content">
                            <span data-v-207fadbf className="item--title">
                                {menu.name}
                            </span>
                            {/**/}
                        </div>
                    </span>)}
                  
                  
                </div>
            </div>)
}

export default RestaurantMenuSections;