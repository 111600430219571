import React, { useEffect, useState } from "react";
import { getCategoryList, getMenu, uniqid } from "../../helpers/helpers";
import SideElement from "./sideElement";
import { observer } from "mobx-react-lite";
import mobxStore from "../../mobx/mobxStore";

const SideMenu = observer(() => {
    const { menuId, setCurrentMenuId,setCurrentCategoryActive,categoryActive } = mobxStore;
    const menus = getMenu();

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if (menuId) {
            setCategories(getCategoryList(menuId));
        }
    }, [menuId]);

    const setMenuActive = (id) => {
        setCurrentMenuId(id);
    };

    return (
        <nav
            data-v-816580ca
            data-v-310e2a5f
            className="side-nav"
            data-testid="sideBarBlock"
        >
            <ul data-v-816580ca className="side-menu">
                {menus.map((menu) => {
                    return (
                        <SideElement
                            key={uniqid()}
                            name={menu.name}
                            id={menu.id}
                            tabActive={menuId}
                            categories={categories}
                            categoryActive={categoryActive}
                            setMenuActive={setMenuActive}
                            setCurrentCategoryActive={
                                setCurrentCategoryActive
                            }
                        />
                    );
                })}
            </ul>
        </nav>
    );
});

export default SideMenu;
