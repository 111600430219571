import React from "react";


const RestaurantInfo=()=>{
return (     <div data-v-11f3e009 className="restaurant-info">
        <div
            data-v-b784aeb6
            data-v-11f3e009
            className="name-wrapper"
        >
    
            <div data-v-b784aeb6 className="info-wrapper">
                <h2
                    data-v-b784aeb6
                    className="title"
                    data-testid="restaurantTitle"
                >
                    SAVORI
                </h2>
               
            </div>
        </div>
    </div>)
}


export default RestaurantInfo;