import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Main from "./pages/main/main";
import "./resp.css";
import "./assets/css/index.css";
import "./assets/css/main.css";
import "./assets/css/modalalertnate.css";
import "./assets/css/workinghours.css";
import "./assets/css/dropdown.css";
//import "./assets/css/index-menu.css";
import "./assets/css/menu.css";
import "./assets/css/alergensblock.css";
import "./assets/css/modal.css";
import "./assets/css/generic.css";
import "./assets/css/menuitembutton.css";
import "./assets/css/dishlist.css";
import "./assets/css/dishlistskeleton.css";
import "./assets/css/dishwish.css";

import "./assets/css/icon.css";
import "./assets/css/changestyle.css";
import Menu from "./pages/menu/menu";
import { useEffect } from "react";
import ModalMenu from "./pages/modalMenu";
import ModalFood from "./pages/modalFood";
import ModalSearch from "./pages/menu/modalSearch";
import mobxStore from "./mobx/mobxStore";


function App() {
 

    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<Main />} />
                <Route path="/menu" element={<Menu />} />

            </Routes>
          <ModalMenu/>
        <ModalFood/>
        <ModalSearch/>
        <div className="safe-bottom"></div>
        </BrowserRouter>
    );
}

export default App;
