import { observer } from "mobx-react-lite";
import React from "react";
import mobxStore from "../mobx/mobxStore";
import { getCategoryList, getMenu } from "../helpers/helpers";
import OutsideAlerter from "../helpers/outsideAlerter";

const ModalMenu = observer(() => {
    const { modalMenuActive, setModalMenuActive,setCurrentMenuId } = mobxStore;
    const menus = getMenu();
    
    if (!modalMenuActive) return <></>;
    return (
        <div
            data-v-fda9d1be
            className="modal-mask"
        
        >
            {/**/}
            <div data-v-fda9d1be className="modal-wrap bottom">
                <OutsideAlerter onOutsideClick={()=>setModalMenuActive(false)} style={{width:"100%"}}>
                <div
                    data-v-fda9d1be
                    className="modal-container from-bottom-animate rounded"
                    style={{ maxWidth: "636px" }}
                >
                    <div data-v-fda9d1be className="modal-header">
                        <h4 data-v-310e2a5f>Menu sections</h4>
                    </div>
                    <div
                        data-v-4d1d4390
                        data-v-fda9d1be
                        className="base-icon close-btn--icon"
                        onClick={()=>setModalMenuActive(false)}
                    >
                        <div data-v-4d1d4390 className="base-icon-content">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7 17L16.5 6.75"
                                    stroke="black"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    id="icon-close-modal"
                                />
                                <path
                                    d="M17.0007 17L6.75073 6.75"
                                    stroke="black"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </div>
                        {/**/}
                    </div>
                    <div data-v-fda9d1be className="modal-body">
                        <div data-v-310e2a5f className="modal-body--wrapper">
                            <div
                                data-v-207fadbf
                                data-v-310e2a5f
                                className="main-menu"
                            >
                                {menus.map((menu) => (
                                    <a
                                        data-v-207fadbf
                                        aria-current="page"
                                        className="router-link-active router-link-exact-active main-menu-item"
                                        data-testid={`menuSection-${menu.id}`}
                                        style={{ boxShadow: "none" }}
                                        onClick={()=>{setCurrentMenuId(menu.id);setModalMenuActive(false)}}
                                    >
                                        <div
                                            data-v-207fadbf
                                            className="item--content"
                                        >
                                            <span
                                                data-v-207fadbf
                                                className="item--title"
                                            >
                                                {menu.name}
                                            </span>
                                            {/**/}
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div data-v-fda9d1be className="modal-footer" />
                </div>
                </OutsideAlerter>
             
            </div>
        </div>
    );
});

export default ModalMenu;
