import React, { useEffect } from "react";

import RestaurantMenuSections from "./restaurantMenuSections";
import RestaurantAbout from "./restaurantAbout";
import RestaurantInfo from "./restaurantInfo";
import mobxStore from "../../mobx/mobxStore";
import { useLocation } from "react-router";
import { observer } from "mobx-react-lite";

const Main = () => {
    const { setSearchModalActive, setModalMenuActive,setModalFoodActive } = mobxStore;

    const location = useLocation();
    useEffect(() => {
        console.log("location change");
        setModalMenuActive(false);
        setSearchModalActive(false);
        setModalFoodActive(-1);
    }, [location]);
    return (
        <div className="index-layout">
            {" "}
            <main data-v-11f3e009>
                <div data-v-11f3e009 className="index-layout-content">
                    <RestaurantInfo />
                    <RestaurantMenuSections />
                    <RestaurantAbout />
                </div>
            </main>
        </div>
    );
};

export default Main;
