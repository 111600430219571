import { categories, dishes, menus } from "../assets/data";

export function uniqid(prefix = "") {
    return `${prefix}${Date.now().toString(36)}${Math.random()
        .toString(36)
        .substring(2, 9)}`;
}

export const getCategoryList = (id) => {
    // get categories of active tab by id.

    const filtered = categories.filter((item) => item.menuId == id);

    return filtered;
};

export const getMenu = () => {
    //get tab list

    return menus;
};

export const getDishes = (id) => {
    // get list of dishes by menu id
    const formatted = [];

    dishes
        .filter((item) => item.menuId == id)
        .forEach((dish) => {
            const dishesIndex = formatted.findIndex(
                (item) => item.categoryId == dish.categoryId
            );
            if (dishesIndex === -1) {
                formatted.push({
                    categoryId: dish.categoryId,
                    dishes: [dish],
                });
            } else {
                formatted[dishesIndex].dishes.push(dish);
            }
        });

    return formatted;
};

export const getCategory = (categoryId) => {
    const categoryIndex = categories.findIndex((item) => item.id == categoryId);

    return categoryIndex !== -1
        ? categories[categoryIndex]
        : { id: -1, name: "", menuId: -1 };
};

export const getMenuItem = (id) => {
    const menuIndex = menus.findIndex((item) => item.id == id);

    return menuIndex !== -1
        ? menus[menuIndex]
        : {
              id: -1,
              name: "",
              showOutsideSchedule: false,
          };
};

export const findDishes = (search="") => {
    const formatted = [];

    dishes
        .filter((item) => item.title.toLowerCase().includes(search.toLowerCase()))
        .forEach((dish) => {
            const dishesIndex = formatted.findIndex(
                (item) => item.categoryId == dish.categoryId
            );
            if (dishesIndex === -1) {
                formatted.push({
                    categoryId: dish.categoryId,
                    dishes: [dish],
                });
            } else {
                formatted[dishesIndex].dishes.push(dish);
            }
        });

    return formatted;
};

export const getFirstCategory=(menuId)=>{
    const item=getCategoryList(menuId);
    console.log("forst item",item);
    
    return item[0];
}

export const searchDish=(id)=>{
    const dishIndex=dishes.findIndex(item=>item.id==id);

    return dishIndex!==-1?dishes[dishIndex]:null;
}